import { JsonObject } from '../../helpers';
import { ConnectorFeatureSet } from './';
import SharepointIcon from '@/assets/connector-icons/sharepoint.svg';
import { ConnectorModel } from './connector.model';
import { trimEnd } from 'lodash';

export type SharepointConfig = {
  clientId: string;
  clientSecret: string;
  tenantId: string;
  sharepointSite: string;
  certInstalled: boolean;
  publicCert: string;
};

export class SharepointConnectorModel extends ConnectorModel {
  config: SharepointConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ConnectorModel, unknown>;

    this.type = 'sharepoint-online';
    this.typeLabel = 'Microsoft Sharepoint';
    this.iconSVG = SharepointIcon;

    this.supportedFeatures = (data.supportedFeatures as ConnectorFeatureSet) || {
      siem: false,
      privateModels: false,
      contextData: true,
      ingress: false,
    };

    this.config = (data.config as SharepointConfig) || {
      clientId: '',
      clientSecret: '',
      tenantId: '',
      sharepointSite: '',
      certInstalled: true, // this field is deprecated
      publicCert: '',
    };

    this.contextProviderTypes = ['surepath-kendra-sharepoint'];
  }

  get sharepointDomain(): string {
    const url = new URL(this.config.sharepointSite);
    return url.hostname.split('.').shift() || '';
  }

  get typedProps(): JsonObject {
    return {
      domain: this.sharepointDomain,
      sharepointSite: trimEnd(this.config.sharepointSite, '/'),
    };
  }
}
