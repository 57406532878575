import { Dataset, DEFAULT_PAGE_SIZE, DtFilter, DtSort } from '@/components/data-table/helpers';
import { GroupMeta, GroupModel } from '@/lib/models/group.model';
import { JsonObject } from '../helpers';
import { UserModel } from '../models/user.model';
import {
  aggregate,
  deleteOne,
  findAll,
  findOne,
  getOidParam,
  getOidParams,
  getOrgFilter,
  getSort,
  insertOne,
  patchOne,
  update,
} from './atlas-data-api.service';
import { getGroupIdsByName } from './group.service';
import { PolicyMeta, PolicyModel } from '@/lib/models/policy.model';
import { DeleteResponse, InsertResponse, UpdateResponse } from '.';
import { ContextProviderMeta } from '../models/context-provider';
import { getProvidersByIds } from './context-provider.service';

const COLLECTION = 'users';

export const searchUsers = async (keyword: string): Promise<UserModel[]> => {
  const $match = getOrgFilter();

  $match.$or = [
    { name: { $regex: `.*${keyword}.*`, $options: 'i' } },
    { email: { $regex: `.*${keyword}.*`, $options: 'i' } },
  ];

  const pipeline = [
    {
      $match,
    },
    {
      $group: {
        _id: '$email',
        doc: { $first: '$$ROOT' },
      },
    },
    {
      $replaceRoot: {
        newRoot: '$doc',
      },
    },
    {
      $project: { name: 1, email: 1, id: 1, orgId: 1 },
    },
  ];

  const rows = await aggregate(COLLECTION, pipeline);

  return rows ? rows.map((data) => new UserModel(data)) : [];
};

export const getUsersAndGroups = async (
  page = 0,
  pageSize = DEFAULT_PAGE_SIZE,
  dtSort?: DtSort,
  dtFilter?: DtFilter
): Promise<[Dataset<UserModel>, Map<string, GroupMeta>]> => {
  const sort = getSort(dtSort) || { name: 1 };
  const skip = page * pageSize;
  const filter = getOrgFilter();

  const groupMeta: Map<string, GroupMeta> = new Map();

  if (dtFilter) {
    const { keyword, values } = dtFilter;
    const andClause: JsonObject[] = [];

    if (keyword) {
      const groupIds = await getGroupIdsByName(keyword);
      andClause.push({
        $or: [
          { name: { $regex: `.*${keyword}.*`, $options: 'i' } },
          { email: { $regex: `.*${keyword}.*`, $options: 'i' } },
          { groups: { $in: getOidParams(groupIds) } },
        ],
      });
    }
    values.forEach(({ columnName, value }) => {
      switch (columnName) {
        case 'user':
          andClause.push({ _id: getOidParam(value as string) });
          break;
      }
    });
    if (andClause.length) {
      filter.$and = andClause;
    }
  }

  const countPipeline = [
    { $match: filter },
    {
      $facet: {
        metadata: [{ $count: 'total' }],
        data: [{ $skip: skip }, { $limit: pageSize }],
      },
    },
  ];

  const queryPipeline = [
    { $match: filter },
    { $skip: skip },
    { $limit: pageSize },
    { $sort: sort || { _id: -1 } },
    {
      $lookup: {
        from: 'groups',
        localField: 'groups',
        foreignField: '_id',
        as: 'groups',
      },
    },
  ];

  const countResponse = await aggregate(COLLECTION, countPipeline);
  let total = 0;
  if (countResponse.length) {
    const { metadata } = countResponse[0] as {
      metadata: [{ total: number }];
    };
    if (metadata?.length) {
      total = metadata[0].total;
    }
  }

  const documents = await aggregate(COLLECTION, queryPipeline);

  const rows: UserModel[] = documents.map((data) => {
    const userGroups = data.groups as JsonObject[];

    userGroups.forEach((data) => {
      groupMeta.set(data._id as string, new GroupModel(data).meta);
    });

    data.groups = userGroups.map(({ _id }) => _id);

    return new UserModel(data);
  });

  return [
    {
      page,
      pageSize,
      rows,
      total,
    },
    groupMeta,
  ];
};

export const getUserGroupsPoliciesContextsById = async (
  id: string
): Promise<
  [
    UserModel | null,
    Map<string, GroupMeta>,
    Map<string, PolicyMeta>,
    Map<string, ContextProviderMeta>,
  ]
> => {
  const groupMeta: Map<string, GroupMeta> = new Map();
  const policyMeta: Map<string, PolicyMeta> = new Map();
  const contextMeta: Map<string, ContextProviderMeta> = new Map();

  const query = [
    {
      $match: {
        _id: getOidParam(id),
      },
    },
    {
      $lookup: {
        from: 'groups',
        localField: 'groups',
        foreignField: '_id',
        as: 'groups',
      },
    },
    {
      $lookup: {
        from: 'policies',
        localField: 'groups.policies',
        foreignField: '_id',
        as: 'policies',
      },
    },
  ];

  const documents = await aggregate(COLLECTION, query);

  if (documents.length !== 1) {
    return [null, new Map(), new Map(), new Map()];
  }

  const userData = documents[0];

  const userGroups = userData.groups as JsonObject[];
  userGroups.forEach((data) => {
    groupMeta.set(data._id as string, new GroupModel(data).meta);
  });
  userData.groups = userGroups.map(({ _id }) => _id);

  const userPolicies = userData.policies as JsonObject[];
  const contextProviderIds: string[] = [];

  userPolicies.forEach((data) => {
    const policy = new PolicyModel(data);

    if (policy.contextDataSources?.enabled?.length) {
      contextProviderIds.push(...policy.contextDataSources.enabled);
    }

    policyMeta.set(policy.id!, policy.meta);
  });

  if (contextProviderIds.length) {
    const contextProviders = await getProvidersByIds(contextProviderIds);
    contextProviders.forEach((provider) => {
      contextMeta.set(provider.id!, provider.meta);
    });
  }

  const user = new UserModel(userData);

  return [user, groupMeta, policyMeta, contextMeta];
};

export const getById = async (userId: string): Promise<UserModel | null> => {
  const document = await findOne(COLLECTION, {
    filter: { _id: getOidParam(userId), ...getOrgFilter() },
  });
  return document ? new UserModel(document) : null;
};

export const getByEmail = async (email: string, excludeUserId?: string): Promise<UserModel[]> => {
  const params = {
    filter: {
      ...(excludeUserId ? { _id: { $ne: getOidParam(excludeUserId) } } : {}),
      email,
      ...getOrgFilter(),
    },
  };

  const documents = await findAll(COLLECTION, params);
  return documents.map((document) => new UserModel(document));
};

export const createUser = async (user: UserModel): Promise<InsertResponse> => {
  const existingUsers = await getByEmail(user.email);

  if (existingUsers.length) {
    return { inserted: false, error: 'That email address is already in use' };
  }

  const { orgId } = getOrgFilter();

  const document = {
    name: user.name,
    email: user.email,
    groups: user.groups ? getOidParams(user.groups) : [],
    orgId,
  };

  const newUserId = await insertOne(COLLECTION, document);

  if (!newUserId) {
    return { inserted: false, error: 'The user could not be created' };
  }

  return { inserted: true, id: newUserId };
};

export const patchUser = async (userId: string, data: JsonObject): Promise<UpdateResponse> => {
  const user = await getById(userId);

  if (!user) {
    return { updated: false, error: 'User not found' };
  }

  const updateData: { name?: string; email?: string; groups?: { $oid: string }[] } = {};

  // once a user has signed in, you can no longer modify their name or email, because we don't currently
  // have a way to validate/sync that change with GCIP
  if (!user.lastSigninTimestamp) {
    if (data.name) {
      updateData.name = data.name as string;
    }
    if (data.email) {
      updateData.email = data.email as string;
    }
  }

  if (Array.isArray(data.groups)) {
    updateData.groups = getOidParams(data.groups as string[]);
  }

  if (updateData.email && user.email !== updateData.email) {
    const existingUsers = await getByEmail(updateData.email, userId);

    if (existingUsers.length) {
      return { updated: false, error: 'That email address is already in use' };
    }
  }

  const filter = {
    _id: { $oid: userId },
    ...getOrgFilter(),
  };

  const response = await patchOne(COLLECTION, filter, updateData);

  return { updated: response === null };
};

export const deleteUser = async (userId: string): Promise<DeleteResponse> => {
  const user = await getById(userId);

  if (!user) {
    return { deleted: false, error: 'User not found' };
  }

  if (!user.canDelete) {
    if (user.isExternal) {
      return { deleted: false, error: 'Cannot delete a sync user' };
    }

    if (user.lastSigninTimestamp) {
      return { deleted: false, error: 'Cannot delete a user that has signed in' };
    }

    return { deleted: false, error: 'Cannot delete user' };
  }

  const filter = {
    _id: getOidParam(userId),
    ...getOrgFilter(),
  };

  const deleted = await deleteOne(COLLECTION, filter);

  return { deleted };
};

export const removeGroupFromUsers = async (groupId: string): Promise<UpdateResponse> => {
  const groupIdParam = getOidParam(groupId);

  const response = await update(
    COLLECTION,
    { groups: groupIdParam },
    { $pull: { groups: groupIdParam } }
  );

  return { updated: response !== null };
};
