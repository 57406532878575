import { FC } from 'react';
import Icon from '../icon';

interface Props {
  onClick: () => void;
}

const OpenDrawerButton: FC<Props> = ({ onClick }) => {
  return <Icon name="chevron-right" onClick={onClick} />;
};

export default OpenDrawerButton;
