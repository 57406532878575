import { FormHandle } from '@/hooks/use-form-handle.hook';
import { FC } from 'react';
import InputControl from '@/components/form/input-control';
import { ViewState } from '@/lib/helpers';
import { Box } from '@mui/material';
import { ContextProviderModel } from '@/lib/models/context-provider/context-provider.model';
import Text from '@/components/text';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import TextInput from '@/components/form/text-input';
import NoneChip from '@/components/chip/none-chip';
import { SharepointConfig } from '@/lib/models/connector/sharepoint-connector.model';
import Card from '@/components/card';

interface Props {
  provider: ContextProviderModel;
  formHandle: FormHandle<ContextProviderModel>;
  mode: ViewState;
  connector?: ConnectorModel | null;
}

const SharepointContextProvider: FC<Props> = ({ formHandle, mode, connector }) => {
  const inWriteMode = ['edit', 'add'].includes(mode);
  const { sharepointSite } = (connector?.config || {}) as SharepointConfig;
  const hasUrl = !!sharepointSite;

  return (
    <>
      {inWriteMode && (
        <Box>
          <Box mt={2} mb={1.5}>
            <Text bold>Connector Base URL</Text>
          </Box>
          <Box mb={2}>
            {hasUrl && (
              <TextInput
                name="sharepointSite"
                value={sharepointSite}
                readonly
                copyClip
                size="small"
              />
            )}
            {!hasUrl && <NoneChip />}
          </Box>

          <Box mb={2}>
            <Card>
              <Box mb={1}>
                <Text bold>Crawl Base Path</Text>
              </Box>
              <Box>
                <Text>
                  When enabled, the connector sharepoint site will be crawled, in addition to any
                  site you specify below in the "Sites" section.
                </Text>
              </Box>

              <InputControl
                name="config.includeBasePath"
                label=""
                formHandle={formHandle}
                type="switch"
                required
              />
            </Card>
          </Box>

          <InputControl
            name="config.paths"
            label="Sites"
            formHandle={formHandle}
            type="list-editor"
            required
            height={90}
            textPrefix="/sites/"
          />
        </Box>
      )}
    </>
  );
};

export default SharepointContextProvider;
