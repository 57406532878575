import { FC } from 'react';
import Drawer, { DrawerProps } from '..';
import DrawerHeader from '../header';
import DrawerContent from '../content';
import { useQuery } from 'react-query';
import { getPubliceServiceById } from '@/lib/services/public-service.service';
import ReadonlyView from '@/components/form/readonly-view';
import { ReadonlyField } from '@/components/form/readonly-view/helpers';
import ExternalLink from '@/components/external-link';
import { getDomain } from '@/lib/url-helpers';
import { PublicServiceModel } from '@/lib/models/public-service.model';
import Chip from '@/components/chip';
import { Box, Stack } from '@mui/material';
import { createUseStyles } from 'react-jss';
import Text from '@/components/text';
import Icon from '@/components/icon';
import NoneChip from '@/components/chip/none-chip';
import { QueryKey } from '@/lib/query-client';

interface Props extends DrawerProps {
  id: string;
}

const fields: ReadonlyField[] = [
  { label: 'Risk Level', prop: 'risk' },
  { label: 'Risk Confidence', prop: 'signalConfidence' },
  { label: 'Trains on User Data', prop: 'trainOnUserData' },
  { label: 'Unmoderated', prop: 'noModeration' },
  { label: 'Country', prop: 'country' },
  { label: 'SurePath AI Support', prop: 'support' },
  { label: 'Company', prop: 'company' },
  { label: 'Description', prop: 'description' },
  { label: 'Website', prop: 'website' },
];

const getReadonlyData = (data: PublicServiceModel | null) => {
  if (!data) {
    return {};
  }

  const {
    name,
    description,
    supportLevel,
    website,
    signals,
    risk,
    riskColor,
    companyName,
    confidenceOption,
  } = data;
  const countryName = signals?.companyCountry?.value;
  const NaChipEl = <NoneChip notAvailable />;
  const YesChipEl = <Chip label="Yes" color="error" />;
  const NoChipEl = <Chip label="No" color="success" />;

  const { trainOnUserData, noModeration } = signals;

  const hasTodSignal = Boolean(trainOnUserData);
  let TodEl = NaChipEl;
  if (hasTodSignal) {
    TodEl = trainOnUserData?.value ? YesChipEl : NoChipEl;
  }

  const hasModSignal = Boolean(noModeration);
  let ModEl = NaChipEl;
  if (hasModSignal) {
    ModEl = noModeration?.value ? YesChipEl : NoChipEl;
  }

  const { label: conLabel, icon: conIcon } = confidenceOption;

  return {
    name,
    company: companyName.value || NaChipEl,
    description,
    country: countryName || NaChipEl,
    website: <ExternalLink href={website} label={getDomain(website)} withIcon />,
    risk: <Chip color={riskColor} label={risk.base.level} />,
    trainOnUserData: (
      <Stack gap={2}>
        <Box>{TodEl}</Box>
        {hasTodSignal && trainOnUserData.description && <Box>{trainOnUserData.description}</Box>}
      </Stack>
    ),
    noModeration: (
      <Stack gap={2}>
        <Box>{ModEl}</Box>
        {hasModSignal && noModeration.description && <Box>{noModeration.description}</Box>}
      </Stack>
    ),
    signalConfidence: (
      <Stack direction="row" gap={0.5}>
        <Icon name={conIcon} />
        <Text>{conLabel}</Text>
      </Stack>
    ),
    support: String(supportLevel).toUpperCase(),
  };
};

const useStyles = createUseStyles({
  favicon: {
    width: 32,
    height: 32,
  },
});

export const PublicServiceDrawer: FC<Props> = ({ id, open, onClose, className }) => {
  const styles = useStyles();

  const query = useQuery([QueryKey.PublicServiceView, id], async () => getPubliceServiceById(id), {
    enabled: !!id,
  });

  const { data = new PublicServiceModel() } = query;
  const publicModel = data || new PublicServiceModel();

  const { name, image } = publicModel;
  const hasImage = Boolean(image);

  const NameEl = (
    <Stack direction="row" gap={2} width="100%">
      {hasImage && <img src={image} className={styles.favicon} />}
      <Box>{name}</Box>
    </Stack>
  );

  return (
    <Drawer open={open} onClose={onClose} className={className} query={query}>
      <DrawerHeader onClose={onClose}>{NameEl}</DrawerHeader>
      <DrawerContent>
        <ReadonlyView fields={fields} data={getReadonlyData(data)} />
      </DrawerContent>
    </Drawer>
  );
};

export default PublicServiceDrawer;
