import { Box, ListItemButton, ListItemIcon, ListItemText, Theme } from '@mui/material';
import { FC, useState, MouseEvent } from 'react';
import { SidebarItem } from './helpers';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { openNewTab, pathIsActive } from '@/lib/url-helpers';
import Icon from '../icon';
import Tooltip from '../tooltip';
import CollapseBox from '../collapse-box';

interface Props {
  item: SidebarItem;
  onNavigate: (href: string) => void;
  open: boolean;
  subItems?: SidebarItem[];
  onOpen?: (open: boolean) => void;
}

const OPEN_MS = 200;

const useStyles = createUseStyles<string, { subItemsOpen: boolean }>((theme: Theme) => ({
  menuButton: {
    color: theme.palette.grey[400],
    display: 'flex',
    justifyContent: 'flex-start',
    '&:not(.isParent).Mui-selected, &:hover': {
      color: theme.palette.common.white,
      backgroundColor: '#f8f8f814 !important',
    },
    '&:hover .MuiSvgIcon-root': {
      color: theme.palette.common.white,
    },

    '& .MuiListItemIcon-root': {
      minWidth: 34,
    },
    marginLeft: 3,
    marginRight: 3,
  },
  expandButton: {
    transform: ({ subItemsOpen }) => (subItemsOpen ? '' : 'rotate(180deg)'),
    transition: `transform ${OPEN_MS}ms`,
  },
  subItems: {
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
    margin: '0px 0px  0px 30px',
  },
}));

const MenuItem: FC<Props> = ({ item, onNavigate, open, subItems = [], onOpen }) => {
  const { link, icon, comingSoon, label } = item;
  const isParent = !!subItems.length;
  const [subItemsOpen, setSubItemsOpen] = useState(
    subItems.some(({ link }) => link && pathIsActive(link))
  );

  const styles = useStyles({ subItemsOpen });
  const compClass = classNames(styles.menuButton, { isParent });
  const active = link ? pathIsActive(link) : false;
  const hasIcon = !!icon;

  const handleClick = (event: MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (comingSoon) {
      return;
    }

    if (isParent) {
      const updatedOpen = !subItemsOpen;
      setSubItemsOpen(updatedOpen);

      if (onOpen) {
        // due to the transformation, the sub-item menu isn't open until after
        // OPEN_MS, so wait for that to fire the onOpen callback
        setTimeout(() => {
          onOpen?.(updatedOpen);
        }, OPEN_MS + 10);
      }

      return;
    }

    if (link) {
      // ctrl+click opens new tab
      if (event.ctrlKey || event.metaKey) {
        openNewTab(link);
        return;
      }

      onNavigate(link);
    }
  };

  const MenuItemEl = (
    <ListItemButton
      className={compClass}
      onClick={handleClick}
      selected={active}
      aria-selected={active}
      disableRipple={!link}
    >
      {hasIcon && (
        <ListItemIcon>
          <Icon name={icon} color={active ? 'white' : 'grey'} />
        </ListItemIcon>
      )}
      {open && <ListItemText primary={label} disableTypography />}
      <Box flexGrow={1} display="flex" justifyContent="flex-end">
        {open && comingSoon && <Icon name="lock-clock" size="small" className={styles.icon} />}
        {open && !comingSoon && isParent && (
          <Icon name="expand" size="small" className={styles.expandButton} />
        )}
      </Box>
    </ListItemButton>
  );

  if (comingSoon) {
    return (
      <Tooltip placement="right" title="This feature will be available soon">
        {MenuItemEl}
      </Tooltip>
    );
  }

  if (!isParent) {
    return MenuItemEl;
  }

  return (
    <>
      {MenuItemEl}
      <CollapseBox className={styles.subItems} open={subItemsOpen}>
        {subItems.map((subItem) => (
          <MenuItem item={subItem} onNavigate={onNavigate} open={open} key={subItem.key} />
        ))}
      </CollapseBox>
    </>
  );
};

export default MenuItem;
