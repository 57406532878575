import { JsonObject } from '../../helpers';
import { ConnectorFeatureSet } from './';
import AwsIcon from '@/assets/connector-icons/aws.svg';
import { ConnectorModel } from './connector.model';

// default to us-east-1 for now since it's the only region bedrock regularly works in
const DEFAULT_AWS_REGION = 'us-east-1';

export type AwsConfig = {
  awsAccountId?: string;
  awsRegion?: string;
  crossAccountRoleARN?: string;
};

export class AwsConnectorModel extends ConnectorModel {
  config: AwsConfig;

  // @todo schema extends base and covers config

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ConnectorModel, unknown>;

    this.type = 'aws';
    this.typeLabel = 'AWS';
    this.iconSVG = AwsIcon;

    this.supportedFeatures = (data.supportedFeatures as ConnectorFeatureSet) || {
      siem: true,
      privateModels: true,
      contextData: false,
      ingress: false,
    };

    this.config = (data.config as AwsConfig) || {
      awsAccountId: '',
      awsRegion: DEFAULT_AWS_REGION,
      crossAccountRoleARN: '',
    };
  }

  static getDefaultRoleARN(accountId: string): string {
    return `arn:aws:iam::${accountId}:role/surepath-cloud-connector-role`;
  }
}
