import { object, string } from 'yup';
import { BaseModel, CreateBaseModel } from './../base.model';
import { JsonObject } from '../../helpers';
import { ConnectorConfig, ConnectorFeatureSet, ConnectorStatus, ConnectorType } from '.';
import { IconType } from '@/components/icon';
import { ContextProviderType } from '../context-provider';

export class ConnectorModel extends BaseModel {
  orgId: string;
  owner: string;
  name: string;
  supportedFeatures: ConnectorFeatureSet;
  type: ConnectorType;
  config: ConnectorConfig;
  status: ConnectorStatus;
  error: string;
  typeLabel: string;
  iconSVG: string;
  icon?: IconType;
  contextProviderTypes: ContextProviderType[];
  highlander: boolean;
  comingSoon: boolean;

  schema = object({
    name: string().required('Name is required'),
    type: string().required('Please choose a type'),
  });

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ConnectorModel, unknown>;

    this.type = (data.type as ConnectorType) || '';
    this.typeLabel = '';
    this.iconSVG = '';

    this.orgId = (data.orgId as string) || '';
    this.name = (data.name as string) || '';
    this.owner = (data.owner as string) || 'customer';
    this.status = (data.status as ConnectorStatus) || 'inactive';
    this.error = (data.error as string) || '';
    this.contextProviderTypes = [];

    this.supportedFeatures = (data.supportedFeatures as ConnectorFeatureSet) || {
      siem: false,
      privateModels: false,
      contextData: false,
      ingress: false,
    };

    this.config = (data.config || {}) as ConnectorConfig;

    this.highlander = false;

    this.comingSoon = false;
  }
  // extending connector-type-specific classes should override this as-needed
  get typedProps(): JsonObject {
    return {};
  }

  get hasContextData(): boolean {
    return !!this.supportedFeatures.contextData;
  }

  get hasIngress(): boolean {
    return !!this.supportedFeatures.ingress;
  }
}

export class CreateConnectorModel extends CreateBaseModel {
  name: string;
  config: ConnectorConfig;
  typedProps: JsonObject;

  constructor(connector: ConnectorModel) {
    super();

    this.name = connector.name;
    this.config = connector.config;
    this.typedProps = connector.typedProps;
  }

  get _props(): Record<string, unknown> {
    return {
      name: this.name,
      ...this.config,
      ...this.typedProps,
    };
  }
}
