import { FC } from 'react';
import { Box, Stack, Theme } from '@mui/material';
import { createUseStyles } from 'react-jss';
import Icon from '../icon';
import Text from '../text';
import { PolicyModel } from '@/lib/models/policy.model';

interface Props {
  policy: PolicyModel;
  onInfoClick?: (policy: PolicyModel) => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  policyButton: {
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: 8,
    borderRadius: theme.shape.borderRadius,
  },
}));

const PolicyButton: FC<Props> = ({ policy, onInfoClick }) => {
  const styles = useStyles();
  const { name, scope } = policy;

  const hasInfoButton = !!onInfoClick;

  const handleClick = () => {
    onInfoClick?.(policy);
  };

  return (
    <Box className={styles.policyButton}>
      <Stack direction="row" gap={1} alignItems="center" width="100%">
        <Icon name={scope === 'org' ? 'organization' : 'group'} size="small" />
        <Text>{name}</Text>
        {hasInfoButton && (
          <Stack flexDirection="row" justifyContent="flex-end" alignItems="center" flexGrow={1}>
            <Icon name="info-circle" onClick={handleClick} size="small" color="grey" />
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default PolicyButton;
