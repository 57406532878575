import { FC } from 'react';
import FormButtons from '@/components/form/form-buttons';
import FormSection from '@/components/form/form-section';
import InputControl from '@/components/form/input-control';
import useFormHandle from '@/hooks/use-form-handle.hook';
import useQueryHelper from '@/hooks/use-query-helper';
import useToast from '@/hooks/use-toast.hook';
import { UiOption } from '@/lib/helpers';
import { PolicyModel } from '@/lib/models/policy.model';
import { QueryKey } from '@/lib/query-client';
import { getOidParam } from '@/lib/services/atlas-data-api.service';
import { getOrgPolicy, patchPolicy } from '@/lib/services/policy.service';
import { getPrivateModels } from '@/lib/services/private-model.service';
import { Box, Grid } from '@mui/material';
import { useQuery } from 'react-query';
import { Form } from '@/components/form';
import InlineToast from '@/components/toasts/inline';
import { SAFE_LIMIT } from '@/lib/services';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';

const fields = ['publicServices', 'defaultPublicServiceAction', 'portal'];
const formModel = new PolicyModel();

const OrgPolicyPublicServicesForm: FC = () => {
  const { errorToast, toast } = useToast();
  const { canChangePolicy } = useFeature();

  const privateModelsQuery = useQuery([QueryKey.PrivateModelsDataset, SAFE_LIMIT], async () =>
    getPrivateModels(0, SAFE_LIMIT)
  );

  const { data: privateModelsData } = privateModelsQuery;
  const privateModels = privateModelsData?.rows || [];

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const originalDefaultModelId = values.portal.defaultModelId;
      if (typeof originalDefaultModelId === 'string') {
        values.portal.defaultModelId = getOidParam(String(values.portal.defaultModelId));
      }
      const updated = await patchPolicy(values.id!, values.propSlice(fields));
      setSubmitting(false);
      values.portal.defaultModelId = originalDefaultModelId;
      resetForm({ values });

      if (updated) {
        toast('The policy was updated');
        return;
      }

      errorToast('The policy was not updated');
      resetForm({ values });
    },
  });

  const query = useQuery([QueryKey.OrgPolicyView], async () => getOrgPolicy());

  const privateModelOptions = privateModels.map(
    ({ name, id }) =>
      ({
        label: name,
        value: id,
      }) as UiOption
  );

  const { showLoader } = useQueryHelper(query);
  const { showLoader: privateModelsLoading } = useQueryHelper(privateModelsQuery);

  if (showLoader || privateModelsLoading) {
    return;
  }

  const hasPrivateModel = !!privateModelOptions.length;

  return (
    <Form formHandle={formHandle} query={query}>
      <PageFeatureToast featureId="change-policy" can={canChangePolicy} />
      <FormSection title="Default Portal Private Model">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Choose the default private model to be used when a user is redirected to the Portal.
          </Grid>
          <Grid item xs={5}>
            <InputControl
              label="Portal Private Model"
              name="portal.defaultModelId"
              type="select"
              disabled={!hasPrivateModel}
              options={privateModelOptions}
              formHandle={formHandle}
              readonly={!canChangePolicy}
            />
          </Grid>
          {!privateModelsLoading && !hasPrivateModel && (
            <Grid item xs={5}>
              <Box mt={2.5}>
                <InlineToast
                  level="warning"
                  message="Create a Private Model to enable this feature"
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </FormSection>
      <FormSection title="Allow Access to Portal">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Enable or disable portal access for your entire organization
          </Grid>
          <Grid item xs={5}>
            <InputControl
              label="Portal Enabled"
              name="portal.enabled"
              type="switch"
              formHandle={formHandle}
              readonly={!canChangePolicy}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection title="Allowed Public Services">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Use the table below to allow or deny public services for this organization. All users
            will have this policy applied, before applying group-level policies, when determining
            access to public services.
          </Grid>
          <Grid item xs={12}>
            <InputControl
              name="publicServices.enabled"
              label="Public Services"
              formHandle={formHandle}
              readonly={!canChangePolicy}
              type="public-service-acl"
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormButtons formHandle={formHandle} readonly={!canChangePolicy} />
    </Form>
  );
};

export default OrgPolicyPublicServicesForm;
