import { JsonObject } from '../../helpers';
import { DEFAULT_AWS_REGION, KendraBaseConfig, KendraMetrics } from './';
import { ContextProviderModel } from './context-provider.model';

export type WebcrawlerConfig = KendraBaseConfig & {
  depth: number;
  includeBasePath: boolean;
  paths: string[];
  completeUrls: string[];
};

export class WebcrawlerContextProviderModel extends ContextProviderModel {
  config: WebcrawlerConfig;
  metrics: KendraMetrics;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ContextProviderModel, unknown>;

    this.type = 'surepath-kendra-webcrawler';
    this.typeLabel = 'Webcrawler';
    this.source = 'webcrawler';

    this.config = (data.config as WebcrawlerConfig) || {
      indexId: '',
      dataSourceId: '',
      region: DEFAULT_AWS_REGION,
      depth: 2,
      includeBasePath: true,
      paths: [],
    };

    this.metrics = (data.metrics as KendraMetrics) || {
      succeededDocsCount: 0,
      failedDocsCount: 0,
      totalDocsCount: 0,
    };
  }
}
