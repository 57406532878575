import { Box, FormHelperText, List, ListItem, Stack, Theme } from '@mui/material';
import { FC } from 'react';
import { FormProps } from '../helpers';
import { createUseStyles } from 'react-jss';
import Text from '@/components/text';
import { UiOption } from '@/lib/helpers';
import Switch from '../switch';

export type ListItem = {
  label: string;
  value: unknown;
  meta?: Record<string, unknown>;
};

interface Props extends FormProps {
  options: UiOption[];
  value: string[];
  onChange: (values: string[]) => void;
  height?: string | number;
}

const useStyles = createUseStyles<string, { height: string | number }>((theme: Theme) => ({
  listEditor: ({ height }) => ({
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    '& ul': {
      height: height || 200,
      overflowY: 'auto',
    },
    '& .MuiFormControl-root': {
      padding: 0,
      margin: 0,
    },
  }),
  listItem: {
    padding: '4px 0px 8px 0px',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  header: {
    padding: 10,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    padding: 10,
  },
}));

const SwitchList: FC<Props> = ({
  label,
  error,
  onChange,
  options,
  name,
  value,
  disabled,
  readonly,
  height = '',
}) => {
  const styles = useStyles({ height });
  const hasError = Boolean(error);

  const handleSwitchChange = (optValue: string, checked: boolean) => {
    let updatedValue = [...value];
    if (checked) {
      updatedValue.push(optValue);
    } else {
      updatedValue = updatedValue.filter((v) => v !== optValue);
    }
    onChange(updatedValue);
  };

  const hasLabel = Boolean(label);

  return (
    <Stack gap={1} className={styles.listEditor}>
      {hasLabel && (
        <Box className={styles.header}>
          <Text bold>{label}</Text>
        </Box>
      )}
      <List>
        {options.map((option, index) => {
          const { label, value: optValue } = option;
          const checked = value.includes(optValue);

          return (
            <ListItem key={index}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                className={styles.listItem}
              >
                <Box flexGrow={1}>{label}</Box>
                <Stack
                  width="60px"
                  gap={0.5}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Switch
                    name={name}
                    checked={checked}
                    onChange={(checked) => handleSwitchChange(optValue, checked)}
                    disabled={disabled || readonly}
                  />
                </Stack>
              </Stack>
            </ListItem>
          );
        })}
      </List>
      {hasError && <FormHelperText error>{error}</FormHelperText>}
    </Stack>
  );
};

export default SwitchList;
