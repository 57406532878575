import { Stack, TextField, Theme } from '@mui/material';
import {
  ChangeEvent,
  FocusEvent,
  FocusEventHandler,
  KeyboardEvent,
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import { FormProps } from '../helpers';
import { createUseStyles } from 'react-jss';
import Icon, { IconType } from '../../icon';
import CopyClipButton from '@/components/copy-clip-button';
import { openNewTab } from '@/lib/url-helpers';
import { addProtocol } from '@/lib/url-helpers';
import Text from '@/components/text';

interface Props extends FormProps {
  value: string;
  onChange?: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
  onEnter?: (value: string) => void;
  type?: string;
  autoComplete?: string;
  rows?: number;
  size?: 'small' | 'medium';
  placeholder?: string;
  clearable?: boolean;
  startIcon?: IconType;
  copyClip?: boolean;
  openUrl?: boolean;
  readonly?: boolean;
  maxLength?: number;
  textPrefix?: string;
}

const useStyles = createUseStyles<string, { isTextarea: boolean }>((theme: Theme) => ({
  textfield: ({ isTextarea }) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    '& label.MuiFormLabel-root.Mui-focused': {
      color: theme.palette.common.black,
    },
    '& .MuiInputBase-root': {
      paddingRight: isTextarea ? 0 : 6,
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: theme.palette.grey[500],
        },
      },
      '&.Mui-readOnly fieldset': {
        borderColor: `${theme.palette.grey[200]} !important`,
      },
      '&.MuiInputBase-adornedStart': {
        paddingLeft: 6,
        whiteSpace: 'nowrap',
      },
      '& input.MuiInputBase-inputAdornedEnd': {
        paddingRight: 12,
      },
      '& input.Mui-readOnly, textarea.Mui-readOnly': {
        color: `${theme.palette.grey[600]} !important`,
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
    ...(isTextarea && {
      '& textarea': {
        paddingRight: 14,
      },
    }),
  }),
  startAdornment: {
    marginRight: 2,
  },
  notchedOutline: {
    borderWidth: '1px !important',
  },
}));

const TextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      label = '',
      onChange,
      onEnter,
      onBlur,
      type = 'text',
      autoComplete = '',
      autoFocus = false,
      value,
      error = '',
      disabled = false,
      required = false,
      rows,
      size,
      placeholder,
      clearable = false,
      copyClip = false,
      openUrl = false,
      startIcon,
      readonly = false,
      maxLength,
      textPrefix = '',
    },
    ref
  ) => {
    const isSmall = size === 'small';
    const isTextarea = type === 'textarea';
    const styles = useStyles({ isTextarea });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (readonly) {
        return;
      }
      onChange && onChange(String(event.target.value), event);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        onEnter && onEnter(value);
      }
    };

    const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
      let value = String(event.target.value);
      if (type === 'url') {
        value = addProtocol(value);
        onChange && onChange(value, event as FocusEvent<HTMLInputElement>);
      }
      onBlur && onBlur(value, event as FocusEvent<HTMLInputElement>);
    };

    const TextareaProps = {
      multiline: false,
      minRows: 5,
      maxRows: 5,
    };

    if (isTextarea) {
      TextareaProps.multiline = true;
      if (rows) {
        TextareaProps.minRows = rows;
        TextareaProps.maxRows = rows;
      }
    }

    const handleClear = () => {
      if (readonly) {
        return;
      }
      onChange && onChange('');
    };

    const handleOpenUrl = () => {
      openNewTab(value);
    };

    let EndAdornmentEl: ReactNode | null = null;
    const endAdornmentEls: ReactNode[] = [];
    const showClearIcon = clearable && Boolean(value);

    if (showClearIcon) {
      endAdornmentEls.push(<Icon name="close" onClick={handleClear} key="clear" />);
    }

    if (openUrl) {
      endAdornmentEls.push(<Icon name="link" onClick={handleOpenUrl} color="link" key="link" />);
    }

    if (copyClip) {
      endAdornmentEls.push(<CopyClipButton value={value || ''} key="copy" />);
    }

    if (endAdornmentEls.length) {
      EndAdornmentEl = (
        <Stack direction="row" alignItems="center" gap={1}>
          {endAdornmentEls}
        </Stack>
      );
    }

    let StartAdornment: ReactNode | null = null;
    if (startIcon) {
      StartAdornment = <Icon className={styles.startAdornment} name="search" />;
    }

    if (textPrefix) {
      StartAdornment = <Text color="grey">{textPrefix}</Text>;
    }

    const compRef = useRef<HTMLInputElement>(null);

    // make sure autofocus actually works
    // https://stackoverflow.com/questions/60476155/is-it-safe-to-use-ref-current-as-useeffects-dependency-when-ref-points-to-a-dom
    useEffect(() => {
      if (!autoFocus) {
        return;
      }
      setTimeout(() => {
        (compRef || ref).current?.focus();
      });
    }, [compRef, ref, autoFocus]);

    return (
      <TextField
        id={name}
        name={name}
        label={label || undefined}
        variant="outlined"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        value={value || ''}
        margin={isSmall ? 'none' : 'normal'}
        fullWidth
        type={type}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        error={!!error}
        helperText={error}
        disabled={disabled}
        {...TextareaProps}
        className={styles.textfield}
        required={required}
        size={size}
        placeholder={placeholder}
        inputRef={ref || compRef}
        inputProps={{ maxLength }}
        InputProps={{
          classes: {
            notchedOutline: styles.notchedOutline,
          },
          startAdornment: StartAdornment,
          endAdornment: EndAdornmentEl,
          readOnly: readonly,
        }}
      />
    );
  }
);

export default TextInput;
