import { FC, useCallback, useEffect, useState } from 'react';
import { Box, Stack, Theme } from '@mui/material';
import Text from '../../text';
import LtList from './lt-list';
import { LoadState, UiOption } from '@/lib/helpers';
import TextInput from '../text-input';
import Checkbox from '../checkbox';
import { createUseStyles } from 'react-jss';
import { debounce } from 'lodash';

interface Props {
  title: string;
  options: UiOption[];
  checked: string[];
  height: string | number;
  onToggle: (values: string[]) => void;
  labelChecks?: boolean;
  loadState: LoadState;
  readonly: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  header: {
    padding: 8,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[100],
    '& .MuiFormControl-root': {
      margin: 0,
      '& label.MuiFormControlLabel-root': {
        margin: '0px 0px 0px 6px',
      },
      '& span.MuiButtonBase-root': {
        padding: 0,
      },
    },
  },
}));

const LtSide: FC<Props> = ({
  title,
  options,
  height,
  onToggle,
  checked,
  labelChecks,
  loadState,
  readonly,
}) => {
  const [keyword, setKeyword] = useState('');
  const [filter, setFilter] = useState('');
  const [checkAll, setCheckAll] = useState(false);
  const styles = useStyles();

  const handleCheckAll = () => {
    const updatedChecked = !checkAll;
    setCheckAll(updatedChecked);
    onToggle(
      options
        .map(({ value }) => value)
        .filter((value) => (updatedChecked ? !checked.includes(value) : checked.includes(value)))
    );
  };

  const handleChangeFilter = useCallback((val: string) => {
    setFilter(val);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandleChangeKeyword = useCallback(
    debounce((keyword: string) => {
      handleChangeFilter(keyword);
    }, 400),
    [handleChangeFilter]
  );

  useEffect(() => {
    if (!options.length || checked.length !== options.length) {
      setCheckAll(false);
    }
  }, [checked, options]);

  useEffect(() => {
    debounceHandleChangeKeyword(keyword);
  }, [keyword, debounceHandleChangeKeyword]);

  const hasOptions = !!options.length;

  return (
    <Stack alignItems="flex-start" width="100%">
      <Box mb={0.5}>
        <Text size="large">{title}</Text>
      </Box>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        width="100%"
        className={styles.header}
      >
        <Box>
          <Checkbox
            name="check-all"
            checked={checkAll}
            onChange={handleCheckAll}
            fullWidth={false}
            disabled={readonly || !hasOptions}
          />
        </Box>
        <Box display="flex" flexGrow={1} justifyContent="flex-end">
          <Box width="50%">
            <TextInput
              name="keyword"
              onChange={setKeyword}
              value={keyword}
              size="small"
              placeholder="Filter by name"
              clearable
              fullWidth
            />
          </Box>
        </Box>
      </Stack>
      <LtList
        options={options}
        filter={filter}
        checked={checked}
        onToggle={onToggle}
        height={height}
        labelChecks={labelChecks}
        loadState={loadState}
        readonly={readonly}
      />
    </Stack>
  );
};

export default LtSide;
