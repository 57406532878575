import { FC, useCallback, useMemo, useState } from 'react';
import { FormProps } from '../helpers';
import { Grid } from '@mui/material';
import Button from '../../button';
import Icon from '../../icon';
import LtSide from './lt-side';
import { UiOption } from '@/lib/helpers';

interface Props extends FormProps {
  value: string[];
  onChange: (options: UiOption[]) => void;
  options: UiOption[];
  leftTitle: string;
  rightTitle: string;
  height?: string | number;
  labelChecks?: boolean;
}

const ListToggle: FC<Props> = ({
  value,
  options,
  onChange,
  leftTitle,
  rightTitle,
  height = 300,
  labelChecks = true,
  loadState = 'loaded',
  readonly = false,
}) => {
  const [leftChecked, setLeftChecked] = useState<string[]>([]);
  const [rightChecked, setRightChecked] = useState<string[]>([]);

  const handleChange = (values: string[]) => {
    onChange(options.filter(({ value: optValue }) => values.includes(optValue)));
  };

  const handleMoveRight = () => {
    const updated = [...value];
    leftChecked.forEach((leftVal) => {
      const lcIndex = updated.indexOf(leftVal);
      if (lcIndex === -1) {
        updated.push(leftVal);
      }
    });

    handleChange(updated);
    setLeftChecked([]);
  };

  const handleMoveLeft = () => {
    const updated = [...value];
    rightChecked.forEach((rightVal) => {
      const rcIndex = updated.indexOf(rightVal);
      if (rcIndex > -1) {
        updated.splice(rcIndex, 1);
      }
    });

    handleChange(updated);
    setRightChecked([]);
  };

  // Update which items have checked checkboxes on the left side
  const handleToggleLeft = useCallback(
    (values: string[]) => {
      const updated = [...leftChecked];

      values.forEach((value) => {
        const checkIndex = updated.indexOf(value);
        if (checkIndex === -1) {
          updated.push(value);
          return;
        }

        updated.splice(checkIndex, 1);
      });

      setLeftChecked(updated);
    },
    [leftChecked]
  );

  const handleToggleRight = useCallback(
    (values: string[]) => {
      const updated = [...rightChecked];

      values.forEach((value) => {
        const checkIndex = updated.indexOf(value);
        if (checkIndex === -1) {
          updated.push(value);
          return;
        }

        updated.splice(checkIndex, 1);
      });

      setRightChecked(updated);
    },
    [rightChecked]
  );

  const [leftList, rightList]: [UiOption[], UiOption[]] = useMemo(() => {
    const leftList: UiOption[] = [];
    const rightList: UiOption[] = [];

    options.forEach((opt) => {
      if (value.includes(opt.value)) {
        rightList.push(opt);
        return;
      }
      leftList.push(opt);
    });

    return [leftList, rightList];
  }, [value, options]);

  const canMoveRight = Boolean(leftChecked.length);
  const canMoveLeft = Boolean(rightChecked.length);

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <LtSide
          title={leftTitle}
          checked={leftChecked}
          options={leftList}
          onToggle={handleToggleLeft}
          height={height}
          labelChecks={labelChecks}
          loadState={loadState}
          readonly={readonly}
        />
      </Grid>
      <Grid item xs={2}>
        <Grid
          container
          direction="column"
          gap={2}
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Grid item>
            <Button
              onClick={handleMoveRight}
              disabled={!canMoveRight}
              label={<Icon name="chevron-right" />}
            />
          </Grid>

          <Grid item>
            <Button
              onClick={handleMoveLeft}
              disabled={!canMoveLeft}
              label={<Icon name="chevron-left" />}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={5}>
        <LtSide
          title={rightTitle}
          checked={rightChecked}
          options={rightList}
          onToggle={handleToggleRight}
          height={height}
          labelChecks={labelChecks}
          loadState={loadState}
          readonly={readonly}
        />
      </Grid>
    </Grid>
  );
};

export default ListToggle;
