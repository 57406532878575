import { JsonObject, UiOption } from '@/lib/helpers';
import { BaseMeta, BaseModel } from '@/lib/models/base.model';
import { array, boolean, object, string } from 'yup';

export type PolicyAccessList = {
  enabled: string[];
  disabled: string[];
};

type PolicyScope = 'org' | 'group';

export type SensitiveDataConfig = {
  action: 'block' | 'redirect';
  detect: boolean;
  entities: string[];
};

export type PortalConfig = {
  enabled: boolean;
  defaultModelId?: string | { $oid: string };
};

export type PolicyMeta = BaseMeta & {
  name: string;
  description: string;
  scope: string;
  groups: string[];
  publicServices: PolicyAccessList;
  contextDataSources: PolicyAccessList;
};

export type PublicServiceAction = 'block' | 'redirect';

export const PublicServiceHandlingOptions: UiOption[] = [
  { label: 'Block', value: 'block' },
  { label: 'Redirect', value: 'redirect' },
];

// @todo J5 Demo
export const IntentOptions: UiOption[] = [
  'Corporate Social Responsibility',
  'Customer Service Or Support',
  'Engineering',
  'Facilities Management',
  'Finance And Accounting',
  'Health Safety And Environment',
  'Human Resources',
  'Information Technology',
  'Legal And Compliance',
  'Marketing',
  'Operations',
  'Product Management',
  'Public Relations',
  'Purchasing And Procurement',
  'Quality Assurance',
  'Sales',
  'Software And Hardware Development',
  'Strategic Planning And Business Development',
  'Supply Chain And Logistics',
].map((name) => ({ label: name, value: name }));

export class PolicyModel extends BaseModel {
  orgId: string;
  name: string;
  description: string;
  publicServices: PolicyAccessList;
  contextDataSources: PolicyAccessList;
  intents: PolicyAccessList;
  sensitiveData: SensitiveDataConfig;
  groups: string[];
  scope: PolicyScope;
  portal: PortalConfig;
  defaultPublicServiceAction: PublicServiceAction;

  schema = object({
    orgId: string().required('Organization is required'),
    name: string().required('Name is required'),
    publicServices: object({
      enabled: array().of(string()).required('Enabled public service ids cannot be empty'),
      disabled: array().of(string()).required('Disabled public service ids cannot be empty'),
    }),
    contextDataSources: object({
      enabled: array().of(string()).required('Enabled datasource ids cannot be empty'),
      disabled: array().of(string()).required('Disabled datasource ids cannot be empty'),
    }),
    sensitiveData: object({
      action: string(),
      detect: boolean(),
      entities: array().of(string()),
    }),
    groups: array().of(string()),
  });

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof PolicyModel, unknown>;

    this.orgId = (data.orgId as string) || '';
    this.name = (data.name as string) || '';
    this.description = (data.description as string) || '';
    this.publicServices = (data.publicServices as PolicyAccessList) || {
      enabled: [],
      disabled: [],
    };
    this.contextDataSources = (data.contextDataSources as PolicyAccessList) || {
      enabled: [],
      disabled: [],
    };
    this.intents = (data.intents as PolicyAccessList) || {
      enabled: [],
      disabled: [],
    };
    this.sensitiveData = (data.sensitiveData as SensitiveDataConfig) || {
      action: 'block',
      detect: false,
      entities: [],
    };
    this.groups = (data.groups as string[]) || [];
    this.scope = (data.scope as PolicyScope) || 'group';
    this.portal = (data.portal as PortalConfig) || { enabled: false };
    this.defaultPublicServiceAction =
      (data.defaultPublicServiceAction as PublicServiceAction) || 'redirect';
  }

  get meta(): PolicyMeta {
    return {
      id: this.id || '',
      name: this.name,
      description: this.description,
      scope: this.scope,
      groups: this.groups,
      publicServices: this.publicServices,
      contextDataSources: this.contextDataSources,
    };
  }
}

export const SensitiveDataActionOptions: UiOption[] = [
  { label: 'Allow', value: 'allow', meta: { header: true } },
  { label: 'Log only', value: 'keep' },
  { label: 'Disallow', value: 'disallow', meta: { header: true } },
  { label: 'Block', value: 'block' },
  { label: 'Redact', value: 'redact', meta: { header: true } },
  { label: 'Tag', value: 'tag' },
  { label: 'Mask', value: 'mask' },
  { label: 'Delete', value: 'delete' },
  { label: 'Synthesize', value: 'synthesize' },
];

export const SensitiveDataTypeOptions: UiOption[] = [
  { label: 'Phone Number', value: 'PHONE_NUMBER' },
  { label: 'Credit Card', value: 'CREDIT_CARD' },
  { label: 'Crypto Address', value: 'CRYPTO' },
  { label: 'Email Address', value: 'EMAIL_ADDRESS' },
  { label: 'IBAN Code', value: 'IBAN_CODE' },
  { label: 'IP Address', value: 'IP_ADDRESS' },
  { label: 'Person', value: 'PERSON' },
  { label: 'Medical License', value: 'MEDICAL_LICENSE' },
  { label: 'URL', value: 'URL' },
  { label: 'US Bank Number', value: 'US_BANK_NUMBER' },
  { label: "US Driver's License Number", value: 'US_DRIVER_LICENSE' },
  { label: 'US Individual Taxpayer Identification Number', value: 'US_ITIN' },
  { label: 'US Passport Number', value: 'US_PASSPORT' },
  { label: 'US Social Security Number', value: 'US_SSN' },
  { label: 'UK NHS Number', value: 'UK_NHS' },
  { label: 'Spanish Tax Identification Number', value: 'ES_NIF' },
  { label: 'Italian Fiscal Code', value: 'IT_FISCAL_CODE' },
  // { label: "Italian Driver's License Number", value: 'IT_DRIVER_LICENSE' },
  { label: 'Italian VAT Code', value: 'IT_VAT_CODE' },
  { label: 'Italian Passport Number', value: 'IT_PASSPORT' },
  { label: 'Italian Identity Card Number', value: 'IT_IDENTITY_CARD' },
  { label: 'Polish National Identification Number', value: 'PL_PESEL' },
  { label: 'Singapore NRIC Number', value: 'SG_NRIC_FIN' },
  { label: 'Singapore Entity Identification Number', value: 'SG_UEN' },
  { label: 'Australian Business Registration Number', value: 'AU_ABN' },
  { label: 'Australian Company Number', value: 'AU_ACN' },
  { label: 'Australian Tax File Number', value: 'AU_TFN' },
  { label: 'Australian Medicare Number', value: 'AU_MEDICARE' },
  { label: 'Indian Permanent Account Number', value: 'IN_PAN' },
  { label: 'Indian Aadhaar Number', value: 'IN_AADHAAR' },
  { label: 'Indian Vehicle Registration Number', value: 'IN_VEHICLE_REGISTRATION' },
];
