import { JsonObject } from '../helpers';

type ModelLifecycle = {
  status: string;
};

type ProviderModelProperties = {
  customizationsSupported: string[];
  inferenceTypesSupported: string[];
  inputModalities: string[];
  outputModalities: string[];
  modelArn: string;
  modelLifecycle: ModelLifecycle;
  modelName: string;
  providerName: string;
  responseStreamingSupported: boolean;
};

/*
 * Corresponds to the response given by the org service /orgs/private-models/available endpoint
 * These are the models that can be used in a PrivateModelModel which in turn are
 * assigned to policies
 */
export class AvailablePrivateModel {
  orgId: string; // Required, matches pattern ^[a-f\d]{24}$
  name: string; // Required, max length 255
  provider: string; // Required, max length 255
  service: string; // Required, max length 255
  modelId: string; // Required
  providerModelProperties: ProviderModelProperties; // Required
  surepathSupported: boolean; // Required

  constructor(props: JsonObject = {}) {
    const data = props as Record<keyof AvailablePrivateModel, unknown>;

    this.orgId = (data.orgId as string) || '';

    this.name = (data.name as string) || '';

    this.provider = (data.provider as string) || '';

    this.modelId = (data.modelId as string) || '';

    this.service = (data.service as string) || '';

    this.surepathSupported = (data.surepathSupported as boolean) || false;

    this.providerModelProperties = (data.providerModelProperties as ProviderModelProperties) || {
      customizationsSupported: [],
      inferenceTypesSupported: [],
      inputModalities: [],
      outputModalities: [],
      modelArn: '',
      modelLifecycle: {
        status: '',
      },
      modelName: '',
      providerName: '',
      responseStreamingSupported: false,
    };
  }

  get iconId(): string {
    const modelToken = String(this.modelId).split('.').pop();
    return String(modelToken).split('-').shift() || '';
  }
}
