import { JsonObject } from '../../helpers';
import { DEFAULT_AWS_REGION, KendraBaseConfig } from './';
import { ContextProviderModel } from './context-provider.model';

export type SharepointConfig = KendraBaseConfig & {
  paths: string[];
  includeBasePath: boolean;
  completeUrls: string[];
};

export class SharepointContextProviderModel extends ContextProviderModel {
  config: SharepointConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ContextProviderModel, unknown>;

    this.type = 'surepath-kendra-sharepoint';
    this.typeLabel = 'Sharepoint';
    this.source = 'sharepoint-online';

    this.config = (data.config as SharepointConfig) || {
      indexId: '',
      dataSourceId: '',
      region: DEFAULT_AWS_REGION,
      paths: [],
      includeBasePath: true,
      completeUrls: [],
    };
  }
}
