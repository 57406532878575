import { FC, useRef, useState } from 'react';

import Chip from '@/components/chip';
import DataTable, { DataTableInstance } from '@/components/data-table';
import { DtColumn, DtFilter, DtSort } from '@/components/data-table/helpers';
import OpenDrawerButton from '@/components/drawers/open-drawer-button';
import Page, { PageProps } from '@/components/page';
import PageHeader, { PageBlurb } from '@/components/page/header';
import Text from '@/components/text';
import useDrawer from '@/hooks/use-drawer';
import { getUsersAndGroups } from '@/lib/services/user.service';
import { Box, Stack } from '@mui/material';
import NoneChip from '@/components/chip/none-chip';
import { GroupMeta } from '@/lib/models/group.model';
import Button from '@/components/button';
import { QueryKey } from '@/lib/query-client';
import DomSize from '@/components/dom-size';
import useFeature from '@/hooks/use-feature';
import Tooltip from '@/components/tooltip';
import PageFeatureToast from '@/components/page-feature-toast';

const columns: DtColumn[] = [
  { name: 'name', label: 'Name', flex: 1 },
  { name: 'email', label: 'Email', flex: 2 },
  { name: 'groups', label: 'Groups', flex: 2, sortable: false },
  { name: 'source', label: 'Source', flex: 0.8, sortable: false },
  { name: 'action', label: '', flex: 0.5, sortable: false },
];

const filter: DtFilter = {
  keyword: '',
  fields: [],
  values: [],
};

const UsersPage: FC<PageProps> = () => {
  const { openDrawer, DrawerEl } = useDrawer('end-user');
  const [selectedRowId, setSelectedRowId] = useState('');
  const { canChangeUsers, getTooltip } = useFeature();

  const dtRef = useRef<DataTableInstance>(null);

  const handleRefresh = () => {
    dtRef.current?.refresh();
  };

  const handleOpenDrawer = (userId?: string) => {
    openDrawer({
      id: userId || null,
      onClose: () => {
        setSelectedRowId('');
      },
      onChange: handleRefresh,
    });

    setSelectedRowId(userId || '');
  };

  const [activeFilter] = useState<DtFilter>({ ...filter });
  const loadData = async (page: number, pageSize: number, sort?: DtSort, filter?: DtFilter) => {
    const [dataset, groupMeta] = await getUsersAndGroups(page, pageSize, sort, filter);
    return {
      ...dataset,
      rows: dataset.rows.map(({ id, name, email, groups, sourceLabel }) => {
        const groupList: GroupMeta[] = [];
        groups.forEach((groupId) => {
          if (groupMeta.has(groupId)) {
            groupList.push(groupMeta.get(groupId)!);
          }
        });

        const hasGroups = !!groups.length;
        const hasName = !!name;

        return {
          id,
          name: hasName ? <Box maxWidth="100%">{name}</Box> : <NoneChip notAvailable />,
          email,
          groups: (
            <Box maxWidth="100%">
              <Text dotdot noWrap size="small">
                {hasGroups ? (
                  <Stack direction="row" gap={1}>
                    {groupList.map(({ id, name }) => (
                      <Chip key={id} label={name} size="small" />
                    ))}
                  </Stack>
                ) : (
                  <NoneChip />
                )}
              </Text>
            </Box>
          ),
          source: <Text size="small">{sourceLabel}</Text>,
          action: (
            <Box width="100%" display="flex" justifyContent="flex-end">
              <OpenDrawerButton onClick={() => handleOpenDrawer(id!)} />
            </Box>
          ),
        };
      }),
    };
  };
  return (
    <Page title="End Users">
      <PageHeader>
        <PageBlurb>View users and their assigned groups.</PageBlurb>
        <Tooltip title={getTooltip('add-user')} disabled={canChangeUsers}>
          <Button
            label="Add User"
            icon="plus"
            size="small"
            onClick={() => handleOpenDrawer()}
            disabled={!canChangeUsers}
          />
        </Tooltip>
      </PageHeader>
      <PageFeatureToast featureId="add-user" can={canChangeUsers} />
      <DomSize>
        <DataTable
          queryKey={QueryKey.UsersDataset}
          searchPlaceholder="Search by name, email, or group"
          columns={columns}
          onLoad={loadData}
          filter={activeFilter}
          search
          poll={15000}
          sort={{ columnName: 'name', direction: 'asc' }}
          selectedRowId={selectedRowId}
          ref={dtRef}
        />
      </DomSize>
      {DrawerEl}
    </Page>
  );
};

export default UsersPage;
