import { FC, useCallback, useRef, useState } from 'react';
import Page, { PageProps } from '../../../components/page';
import DataTable, { DataTableInstance } from '../../../components/data-table';
import { Box, Stack } from '@mui/material';
import Text from '../../../components/text';
import PageHeader, { PageBlurb } from '../../../components/page/header';
import { DtColumn, DtFilter, DtSort } from '@/components/data-table/helpers';
import { getDuplicateGroupNames, getGroups } from '@/lib/services/group.service';
import useDrawer from '@/hooks/use-drawer';
import OpenDrawerButton from '@/components/drawers/open-drawer-button';
import Button from '@/components/button';
import Chip from '@/components/chip';
import { QueryKey } from '@/lib/query-client';
import DomSize from '@/components/dom-size';
import useFeature from '@/hooks/use-feature';
import Tooltip from '@/components/tooltip';
import PageFeatureToast from '@/components/page-feature-toast';

const columns: DtColumn[] = [
  { name: 'name', label: 'Name', flex: 7 },
  { name: 'source', label: 'Source', sortable: false },
  { name: 'action', label: '', sortable: false },
];

const GroupsPage: FC<PageProps> = () => {
  const { openDrawer, DrawerEl } = useDrawer('group');
  const [selectedRowId, setSelectedRowId] = useState('');
  const [duplicateGroupNames, setDuplicateGroupNames] = useState<string[] | null>(null);
  const { canChangeGroups, getTooltip } = useFeature();

  const dtRef = useRef<DataTableInstance>(null);

  const handleRefresh = () => {
    setDuplicateGroupNames(null);

    // @todo gotta be a better way to do this
    setTimeout(() => {
      dtRef.current?.refresh();
    }, 10);
  };

  const handleOpenDrawer = (groupId?: string) => {
    openDrawer({
      id: groupId || null,
      onClose: () => {
        setSelectedRowId('');
      },
      onChange: handleRefresh,
    });

    setSelectedRowId(groupId || '');
  };

  const handleGetDuplicateGroupNames = useCallback(async (): Promise<string[]> => {
    if (Array.isArray(duplicateGroupNames)) {
      return duplicateGroupNames;
    }

    return getDuplicateGroupNames().then((groupNames) => {
      setDuplicateGroupNames(groupNames);
      return groupNames;
    });
  }, [duplicateGroupNames]);

  const loadData = async (page: number, pageSize: number, sort?: DtSort, filter?: DtFilter) => {
    const [dataset, duplicateGroupNames] = await Promise.all([
      getGroups(page, pageSize, sort, filter),
      handleGetDuplicateGroupNames(),
    ]);

    return {
      ...dataset,
      rows: dataset.rows.map(({ id, name, description, sourceLabel }) => {
        const hasDuplicate = duplicateGroupNames.includes(name);
        return {
          id,
          name: (
            <Stack
              direction="row"
              width="100%"
              maxWidth="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box flexGrow={1}>
                <Box>{name}</Box>
                <Box className="description">
                  <Text color="grey" size="small" dotdot>
                    {description}
                  </Text>
                </Box>
              </Box>

              {hasDuplicate && <Chip label="Duplicate Name" size="small" color="warning" />}
            </Stack>
          ),
          source: sourceLabel,
          action: (
            <Box width="100%" display="flex" justifyContent="flex-end">
              <OpenDrawerButton onClick={() => handleOpenDrawer(id!)} />
            </Box>
          ),
        };
      }),
    };
  };

  return (
    <Page title="Groups">
      <PageHeader>
        <PageBlurb>Apply intent, data, and model access policies to groups of users.</PageBlurb>
        <Tooltip title={getTooltip('add-group')} disabled={canChangeGroups}>
          <Button
            label="Add Group"
            icon="plus"
            size="small"
            onClick={() => handleOpenDrawer()}
            disabled={!canChangeGroups}
          />
        </Tooltip>
      </PageHeader>
      <PageFeatureToast featureId="add-group" can={canChangeGroups} />
      <DomSize>
        <DataTable
          queryKey={QueryKey.GroupsDataset}
          searchPlaceholder="Search by group name"
          columns={columns}
          onLoad={loadData}
          rowHeight={70}
          search={true}
          selectedRowId={selectedRowId}
          ref={dtRef}
          sort={{ columnName: 'name', direction: 'asc' }}
        />
      </DomSize>
      {DrawerEl}
    </Page>
  );
};

export default GroupsPage;
