import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import Page, { PageProps } from '../../components/page';
import { Box, Stack } from '@mui/material';
import DataTable from '../../components/data-table';
import Icon from '../../components/icon';
import PageHeader, { PageBlurb } from '../../components/page/header';
import Chip from '../../components/chip';
import useDrawer from '../../hooks/use-drawer';
import { DtColumn, DtFilter, DtFilterValue, DtSort } from '@/components/data-table/helpers';
import { capitalize } from 'lodash';
import Tooltip from '@/components/tooltip';
import ActionChip from '@/components/action-chip';
import NiceDate from '@/components/nice-date';
import Text from '@/components/text';
import { searchUsers } from '@/lib/services/user.service';
import OpenDrawerButton from '@/components/drawers/open-drawer-button';
import NoneChip from '@/components/chip/none-chip';
import useAppData from '@/hooks/use-app-data.hook';
import { PublicServiceMeta } from '@/lib/models/public-service.model';
import { getUserEvents } from '@/lib/services/user-event.service';
import { UserEventAction } from '@/lib/models/user-event.model';
import { getUserEventActionLabel } from '@/components/action-chip/helpers';
import useApp from '@/hooks/use-app.hook';
import { AppDataQueryKey, QueryKey } from '@/lib/query-client';
import DomSize from '@/components/dom-size';
import useFeature from '@/hooks/use-feature';
import useQueryHelper from '@/hooks/use-query-helper';
import FlatButton from '@/components/flat-button';

const columns: DtColumn[] = [
  { name: 'user', label: 'User', flex: 2, sortable: false },
  { name: 'intent', label: 'Intent', sortable: false },
  { name: 'model', label: 'Model' },
  { name: 'violations', label: 'Violations', sortable: false },
  { name: 'action', label: 'Result' },
  { name: 'startTime', label: 'Time' },
  { name: 'actions', label: 'Actions', flex: 0.6, sortable: false },
];

const filter: DtFilter = {
  keyword: '',
  fields: [
    { columnName: 'startTime', label: 'Date', control: 'date-range' },
    {
      columnName: 'action',
      label: 'Result',
      control: 'select-multi',
      highlander: true,
      options: [
        UserEventAction.ALLOW,
        UserEventAction.BLOCK,
        UserEventAction.PORTAL,
        UserEventAction.DOWNTIME,
        UserEventAction.ERROR,
        UserEventAction.INTERCEPTAUTH,
        UserEventAction.REDACT,
      ].map((action) => ({
        label: capitalize(getUserEventActionLabel(action)),
        value: String(action),
      })),
    },
    {
      columnName: 'violation',
      label: 'Violation',
      control: 'select-multi',
      highlander: true,
      options: [
        { label: 'PII', value: 'pii' },
        { label: 'Intent', value: 'intent' },
        { label: 'Access', value: 'access' },
      ],
    },
    {
      columnName: 'user',
      label: 'User',
      searchLabel: 'Search by name and email',
      control: 'select-search',
      onSearch: async (value: string) => {
        const users = value ? await searchUsers(value) : [];
        return users.map(({ name, email, id }) => ({
          label: name && email ? `${name} <${email}>` : name || email,
          value: id!,
        }));
      },
    },
  ],
  values: [],
};

const addLikeActionFilterValue = (
  filterValues: DtFilterValue[],
  sourceAction: UserEventAction,
  likeActionValue: UserEventAction
): DtFilterValue[] => {
  const sourceActionVal = String(sourceAction);
  const likeActionVal = String(likeActionValue);

  for (const filterValue of filterValues) {
    const { columnName, value } = filterValue;
    if (columnName === 'action') {
      const hasMultiple = Array.isArray(value);
      const hasSourceAction =
        (hasMultiple && value.includes(sourceActionVal)) || value === sourceActionVal;
      const needsLikeAction = hasSourceAction && (!hasMultiple || !value.includes(likeActionVal));
      if (hasSourceAction && needsLikeAction) {
        if (!hasMultiple) {
          filterValue.value = [filterValue.value];
        }
        (filterValue.value as string[]).push(likeActionVal);
      }
    }
  }

  return filterValues;
};

const UserEventsPage: FC<PageProps> = () => {
  const { isDemo } = useApp();
  const { openDrawer, DrawerEl } = useDrawer('user-event');
  const { openDrawer: openConversationDrawer, DrawerEl: ConversationDrawerEl } =
    useDrawer('conversation');
  const { openDrawer: openPsDrawer, DrawerEl: PsDrawerEl } = useDrawer('public-service');
  const [selectedRowId, setSelectedRowId] = useState('');
  const { canViewConversations, loading: featuresLoading, getTooltip } = useFeature();

  /*
   * As a convenience feature, if a user event policy decision is either "block" or "inline block", we
   * simply show "block" in the admin. So when filtering on policy decision, we need to ensure that
   * both "block" and "inline block" events are included in filtered results. And the same goes
   * for "allow" and "reroute". For the user, the displayed policy decision is just "allow".
   */
  const handleChangeFilter = (filterValues: DtFilterValue[]) => {
    addLikeActionFilterValue(filterValues, UserEventAction.BLOCK, UserEventAction.INLINEBLOCK);
    addLikeActionFilterValue(filterValues, UserEventAction.ALLOW, UserEventAction.REROUTE);
    addLikeActionFilterValue(filterValues, UserEventAction.ERROR, UserEventAction.HTTPERROR);
    return filterValues;
  };

  const handleView = useCallback(
    (id: string) => {
      setSelectedRowId(id);
      openDrawer({
        id,
        onClose: () => {
          setSelectedRowId('');
        },
      });
    },
    [openDrawer]
  );

  const handleViewConversation = useCallback(
    (id: string, requestId: string, conversationId: string) => {
      setSelectedRowId(id);
      openConversationDrawer({
        requestId,
        conversationId,
        onClose: () => {
          setSelectedRowId('');
        },
      });
    },
    [openConversationDrawer]
  );

  const handleOpenPsDrawer = useCallback(
    (id: string, psId: string) => {
      setSelectedRowId(id);

      openPsDrawer({
        id: psId,
        onClose: () => {
          setSelectedRowId('');
        },
      });
    },
    [openPsDrawer]
  );

  const psQuery = useAppData<PublicServiceMeta[]>(AppDataQueryKey.PublicServices);
  const { data: publicServices = [] } = psQuery;
  const { showLoader: psLoading } = useQueryHelper(psQuery);

  // add the default filter to exclude errors, when demoing
  const compFilter = useMemo(() => {
    if (!isDemo) {
      return filter;
    }

    return {
      ...filter,
      values: [
        {
          columnName: 'action',
          value: [
            UserEventAction.ALLOW,
            UserEventAction.REROUTE,
            UserEventAction.BLOCK,
            UserEventAction.INLINEBLOCK,
            UserEventAction.PORTAL,
            UserEventAction.DOWNTIME,
            UserEventAction.INTERCEPTAUTH,
            UserEventAction.REDACT,
          ].map((value) => String(value)),
        },
      ],
    };
  }, [isDemo]);

  const loadData = useCallback(
    async (page: number, pageSize: number, sort?: DtSort, filter?: DtFilter) => {
      const result = await getUserEvents(
        page,
        pageSize,
        sort || { columnName: 'startTime', direction: 'desc' },
        filter
      );

      return {
        page,
        pageSize,
        sort,
        filter,
        rows: result.rows.map(
          ({
            id,
            conversationId,
            highIntent,
            userClaim,
            startTime,
            serviceId,
            serviceName,
            policyDecision,
            violations,
            messages,
          }) => {
            const { name, email } = userClaim;
            const hasConversation = Boolean(messages?.conversation.length);
            const publicService = publicServices.find(({ id }) => id === serviceId);

            let NameEl: ReactNode = null;
            if (name && email) {
              NameEl = (
                <Stack maxWidth="100%">
                  <Text size="small" dotdot>
                    {name}
                  </Text>
                  <Text size="small" color="grey" dotdot>
                    {email}
                  </Text>
                </Stack>
              );
            } else {
              NameEl = <Text size="small">{name || email}</Text>;
            }

            let ServiceEl = (
              <Text dotdot size="small">
                {serviceName}
              </Text>
            );

            if (publicService) {
              const { name, id: psId } = publicService;
              ServiceEl = (
                <FlatButton
                  size="small"
                  label={name}
                  onClick={() => handleOpenPsDrawer(id!, psId)}
                  color="link"
                />
              );
            }

            const historyDisabled = !canViewConversations || !hasConversation;

            return {
              id,
              user: NameEl,
              intent: highIntent?.departmentName || <NoneChip notAvailable />,
              model: ServiceEl,
              action: <ActionChip action={policyDecision} />,
              violations: violations?.length ? (
                violations.map((violation) => (
                  <Chip
                    key={violation}
                    label={violation.toUpperCase()}
                    color="error"
                    size="small"
                  />
                ))
              ) : (
                <NoneChip />
              ),
              startTime: (
                <Stack gap={0.2}>
                  <NiceDate size="x-small" val={startTime} withTime />
                </Stack>
              ),
              actions: (
                <Box width="100%" display="flex" justifyContent="space-between">
                  <Tooltip
                    title={
                      !canViewConversations
                        ? getTooltip('view-conversation')
                        : 'No conversation history'
                    }
                    disabled={!historyDisabled}
                  >
                    <Icon
                      name="chat"
                      disabled={historyDisabled}
                      onClick={() =>
                        handleViewConversation(id!, messages.requestId, conversationId)
                      }
                    />
                  </Tooltip>
                  <OpenDrawerButton onClick={() => handleView(id!)} />
                </Box>
              ),
            };
          }
        ),
        total: result.total,
      };
    },
    [
      publicServices,
      handleView,
      handleViewConversation,
      canViewConversations,
      getTooltip,
      handleOpenPsDrawer,
    ]
  );

  if (featuresLoading || psLoading) {
    return null;
  }

  return (
    <Page title="User Activity">
      <PageHeader>
        <PageBlurb>
          View detailed user activity, including related models, conversations, and policy
          decisions.
        </PageBlurb>
      </PageHeader>
      <DomSize>
        <DataTable
          queryKey={QueryKey.UserEventsDataset}
          searchPlaceholder="Search by message or trace Id"
          columns={columns}
          onLoad={loadData}
          onChangeFilter={handleChangeFilter}
          rowHeight={70}
          filter={compFilter}
          search
          poll={15000}
          selectedRowId={selectedRowId}
        />
      </DomSize>
      {ConversationDrawerEl}
      {DrawerEl}
      {PsDrawerEl}
    </Page>
  );
};

export default UserEventsPage;
