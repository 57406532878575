import { FC } from 'react';
import { useQuery } from 'react-query';
import { getPolicyById, patchPolicy } from '@/lib/services/policy.service';
import useToast from '@/hooks/use-toast.hook';
import { IntentOptions, PolicyModel } from '@/lib/models/policy.model';
import FormSection from '@/components/form/form-section';
import { Grid } from '@mui/material';
import useQueryHelper from '@/hooks/use-query-helper';
import InputControl from '@/components/form/input-control';
import useFormHandle from '@/hooks/use-form-handle.hook';
import FormButtons from '@/components/form/form-buttons';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';

const fields = ['intents'];
const formModel = new PolicyModel();

interface Props {
  policyId: string;
}

const IntentForm: FC<Props> = ({ policyId }) => {
  const { toast, errorToast } = useToast();
  const { canChangePolicy } = useFeature();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const patched = await patchPolicy(values.id!, values.propSlice(fields));
      setSubmitting(false);
      resetForm({ values });

      if (patched) {
        toast('The policy was updated');
        return;
      }

      errorToast('The policy was not updated');
    },
  });

  const query = useQuery([QueryKey.PolicyView, policyId], async () => getPolicyById(policyId));
  const { showLoader } = useQueryHelper(query);

  if (showLoader) {
    return;
  }

  return (
    <Form formHandle={formHandle} query={query}>
      <PageFeatureToast featureId="change-policy" can={canChangePolicy} />
      <FormSection title="Allowed Intents">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Use the table below to allow or deny intents for this group. Users belonging to this
            group will only have access to intents listed on the right under "Allowed Intents".
          </Grid>
          <Grid item xs={12}>
            <InputControl
              name="intents.disabled"
              label="Intents"
              formHandle={formHandle}
              readonly={!canChangePolicy}
              type="list-toggle"
              leftTitle="Allowed Intents"
              rightTitle="Blocked Intents"
              options={IntentOptions}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormButtons formHandle={formHandle} readonly={!canChangePolicy} />
    </Form>
  );
};

export default IntentForm;
