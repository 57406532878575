import Button from '@/components/button';
import Icon from '@/components/icon';
import Text from '@/components/text';
import useToast from '@/hooks/use-toast.hook';
import { OrgModel } from '@/lib/models/org.model';
import { patchOrg } from '@/lib/services/org.service';
import { Box, Stack, Theme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import PreviewCard from './preview-card';
import InlineToast from '@/components/toasts/inline';

export type FileWithPreview = FileWithPath & { preview: string };

interface Props {
  organization: OrgModel;
  readonly: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  uploadForm: {
    width: '50%',
    boxSizing: 'border-box',
    marginRight: 100,
    paddingRight: 16,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  uploader: {
    '& .dropzone': {
      cursor: 'pointer',
      padding: 15,
      backgroundColor: theme.palette.grey[50],
      borderRadius: theme.shape.borderRadius,
      border: `1px dashed ${theme.palette.grey[200]}`,
      color: theme.palette.grey[700],
    },
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 2,
    width: 300,
  },
  thumb: {
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: 4,
  },
  thumbPlaceholder: {
    backgroundColor: theme.palette.grey[200],
    width: 300,
    height: 50,
    borderRadius: theme.shape.borderRadius,
  },
  imagePlaceholder: {
    border: `1px dashed ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    boxSizing: 'border-box',
  },
  imagePreview: {
    display: 'block',
    maxWidth: 250,
    maxHeight: 40,
    width: 'auto',
    height: 'auto',
  },
}));

const MAX_FILE_SIZE_KB = 300;
const IMAGE_WIDTH_PX = 250;
const IMAGE_HEIGHT_PX = 40;

const OrgLogoUploadForm: FC<Props> = ({ organization, readonly }) => {
  const styles = useStyles();

  const { toast, errorToast } = useToast();
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [uploadError, setUploadError] = useState('');
  const [logo, setLogo] = useState(organization.branding.lightThemeLogo || '');

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      // 'image/svg+xml': [],
    },
    onDrop: (acceptedFiles: FileWithPath[]) => {
      setUploadError('');
      if (!acceptedFiles.length) {
        return;
      }

      const file = acceptedFiles[0];

      if (file.size > MAX_FILE_SIZE_KB * 1000) {
        console.error('image invalid via size', file);
        setUploadError(`Please limit the image to a maximum size of ${MAX_FILE_SIZE_KB}kb`);
        setFiles([]);
        return;
      }

      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        // if (file.type !== 'image/svg+xml' && image.width > MAX_IMAGE_WIDTH_PX) {
        //   console.error('image invalid via width', image);
        //   setUploadError(`Please limit the image to a maximum width of ${MAX_IMAGE_WIDTH_PX}px`);
        //   setFiles([]);
        //   return;
        // }

        setFiles([Object.assign(file, { preview: URL.createObjectURL(file) })]);
      };
    },
  });

  const handleAccept = () => {
    if (!files.length) {
      return;
    }

    const file = files[0];

    const reader = new FileReader();

    reader.onload = (loadEvent) => {
      const dataURL = loadEvent?.target?.result;
      if (!dataURL) {
        setUploadError('There was an error uploading your file. Please try again.');
        setFiles([]);
        return;
      }

      setUploadError('');
      setFiles([]);
      handleUploadLogo(String(dataURL));
    };

    reader.readAsDataURL(file);
  };

  const handleUploadLogo = (dataURL: string) => {
    const updatedOrg = new OrgModel(organization._props);
    updatedOrg.branding.lightThemeLogo = dataURL;

    patchOrg(updatedOrg).then(({ patched, error }) => {
      if (patched) {
        setLogo(dataURL);
        toast('The organization logo was updated');
        return;
      }

      errorToast(error || 'The organization logo was not updated');
    });
  };

  const handleRemoveLogo = () => {
    const updatedOrg = new OrgModel(organization._props);
    updatedOrg.branding.lightThemeLogo = '';

    patchOrg(updatedOrg).then(({ patched, error }) => {
      if (patched) {
        setLogo('');
        toast('The organization logo was removed');
        return;
      }

      errorToast(error || 'The organization logo was not removed');
    });
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(({ preview }) => URL.revokeObjectURL(preview));
  }, [files]);

  const canUpload = files.length === 1;
  const hasError = Boolean(uploadError);
  const hasFiles = !!files.length;
  const hasLogo = Boolean(logo);

  return (
    <>
      <Box marginBottom={6}>
        The Organization Logo will appear in the Portal at the top of the sidebar, and will also
        appear at the top of the Portal Welcome Message.
      </Box>
      <Stack direction="row">
        {!readonly && (
          <Box className={styles.uploadForm}>
            <Box className={styles.uploader}>
              <Box mb={2}>
                <Text bold>Upload New Logo Image</Text>
              </Box>
              <Box
                {...getRootProps({ className: 'dropzone' })}
                height={200}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <input {...getInputProps()} />
                <Box mr={1}>
                  <Icon name="upload" size="large" />
                </Box>
                <Box>Drag and drop an image file here, or click to choose a file</Box>
              </Box>

              <Stack mt={3} gap={1}>
                <Text bold size="small">
                  Upload Preview
                </Text>
                <Box className={styles.thumbsContainer}>
                  {files.map(({ name, preview }) => (
                    <Box className={styles.thumb} key={name}>
                      <img
                        src={preview}
                        className={styles.imagePreview}
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                          URL.revokeObjectURL(preview);
                        }}
                      />
                    </Box>
                  ))}
                  {!hasFiles && <Box className={styles.thumbPlaceholder}></Box>}
                </Box>
              </Stack>
              <Stack mt={3} gap={1}>
                <Text bold size="small">
                  Upload Size and Format
                </Text>
                <Text size="small">
                  Please upload only PNG or JPG files with a maximum size of {MAX_FILE_SIZE_KB}kb.
                </Text>
              </Stack>
              {/* <Stack mt={3} gap={1}>
        <Text bold size="small">
          Image Dimensions
        </Text>
        <Text size="small">
          When displayed, your logo will be sized to a maximum width of {MAX_IMAGE_WIDTH_PX}px and
          constrained to a maximum height of {MAX_IMAGE_HEIGHT_PX}px.
        </Text>
      </Stack> */}
              <Stack justifyContent="space-between" direction="row" mt={6}>
                <Button label="upload" disabled={!canUpload} onClick={handleAccept} type="submit" />

                {!readonly && (
                  <Button
                    label="remove logo"
                    type="delete"
                    disabled={!hasLogo}
                    onClick={handleRemoveLogo}
                    size="small"
                  />
                )}
              </Stack>

              {hasError && (
                <Box mt={2}>
                  <InlineToast message={uploadError} level="error" />
                </Box>
              )}
            </Box>
          </Box>
        )}
        <Box width="50%">
          <PreviewCard
            imageHeight={IMAGE_HEIGHT_PX}
            imageWidth={IMAGE_WIDTH_PX}
            maxFileSize={MAX_FILE_SIZE_KB}
          >
            <Stack gap={4}>
              <Box>
                <Text size="small">
                  The Organization Logo will be constrained to maximum height of {IMAGE_HEIGHT_PX}
                  px, and a maximum width of {IMAGE_WIDTH_PX}px.
                </Text>
              </Box>
              <Box>
                {hasLogo && (
                  <Box width="300">
                    <img src={logo} className={styles.imagePreview} />
                  </Box>
                )}
                {!hasLogo && (
                  <Box className={styles.imagePlaceholder} width={IMAGE_WIDTH_PX}>
                    <Text size="small" color="grey">
                      No image available
                    </Text>
                  </Box>
                )}
              </Box>
            </Stack>
          </PreviewCard>
        </Box>
      </Stack>
    </>
  );
};

export default OrgLogoUploadForm;
