import { CSSProperties, FC, useCallback, useMemo } from 'react';
import { Box, List, ListItem, Skeleton, Theme } from '@mui/material';
import { createUseStyles } from 'react-jss';
import Text from '../../text';
import { LoadState, UiOption } from '@/lib/helpers';
import ListToggleItem from './lt-item';
import { FixedSizeList } from 'react-window';

interface Props {
  options: UiOption[];
  checked: string[];
  onToggle: (values: string[]) => void;
  height: string | number;
  rowHeight?: number;
  filter?: string;
  labelChecks?: boolean;
  loadState: LoadState;
  readonly: boolean;
}

const useStyles = createUseStyles<string, { height: number | string }>((theme: Theme) => ({
  list: ({ height }) => ({
    height,
    border: `1px solid ${theme.palette.grey[200]}`,
  }),
  divider: {
    marginBottom: 16,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));

const LtList: FC<Props> = ({
  options,
  checked,
  onToggle,
  height,
  rowHeight = 50,
  filter = '',
  labelChecks = true,
  loadState,
  readonly,
}) => {
  const styles = useStyles({ height });

  const filteredOptions = useMemo(() => {
    const safeFilter = filter.trim().toLowerCase();
    return [...options]
      .filter((opt) => {
        if (!safeFilter) {
          return true;
        }
        const keywordVal = (opt.meta?.keyword as string) || String(opt.label);
        return keywordVal.toLowerCase().includes(safeFilter);
      })
      .sort((aOpt, bOpt) => {
        const aLabel = String((aOpt.meta?.keyword as string) || aOpt.label).toLowerCase();
        const bLabel = String((bOpt.meta?.keyword as string) || bOpt.label).toLowerCase();
        return aLabel.localeCompare(bLabel);
      });
  }, [options, filter]);

  const noMatch = !filteredOptions.length && !!filter;
  const loaded = loadState === 'loaded';

  const FixedListRow = useCallback(
    ({ index, style }: { index: number; style: CSSProperties }) => {
      const { value, label } = filteredOptions[index];

      return (
        <ListToggleItem
          value={value}
          checked={checked.includes(value)}
          label={label}
          onChange={onToggle}
          labelChecks={labelChecks}
          disabled={readonly}
          style={style}
        />
      );
    },
    [checked, filteredOptions, labelChecks, readonly, onToggle]
  );

  return (
    <Box className={styles.list} width="100%">
      {!loaded && <Skeleton variant="rectangular" width="100%" height="100%" />}
      {loaded && noMatch && (
        <List dense component="div" role="list">
          <ListItem>
            <Text italic color="grey">
              No matches found
            </Text>
          </ListItem>
          <ListItem className={styles.divider} />
        </List>
      )}

      {loaded && (
        <FixedSizeList
          height={height}
          itemCount={filteredOptions.length}
          itemSize={rowHeight}
          width="100%"
        >
          {FixedListRow}
        </FixedSizeList>
      )}
    </Box>
  );
};

export default LtList;
