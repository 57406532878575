import { Box, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode;
}

const useStyles = createUseStyles((theme: Theme) => ({
  cardRow: {
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: '8px 12px',
    borderRadius: theme.shape.borderRadius,
    marginBottom: 8,
  },
}));

const CardRow: FC<Props> = ({ children }) => {
  const styles = useStyles();
  return <Box className={styles.cardRow}>{children}</Box>;
};

export default CardRow;
