import { FC } from 'react';
import { useQuery } from 'react-query';
import { Form } from '@/components/form';
import FormButtons from '@/components/form/form-buttons';
import FormSection from '@/components/form/form-section';
import InputControl from '@/components/form/input-control';
import useFormHandle from '@/hooks/use-form-handle.hook';
import useQueryHelper from '@/hooks/use-query-helper';
import useToast from '@/hooks/use-toast.hook';
import { PolicyModel } from '@/lib/models/policy.model';
import { QueryKey } from '@/lib/query-client';
import { getPolicyById, patchPolicy } from '@/lib/services/policy.service';
import { Grid } from '@mui/material';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';

const fields = ['publicServices', 'defaultPublicServiceAction', 'portal'];
const formModel = new PolicyModel();

interface Props {
  policyId: string;
}

const PolicyPublicServicesForm: FC<Props> = ({ policyId }) => {
  const { errorToast, toast } = useToast();
  const { canChangePolicy } = useFeature();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const updated = await patchPolicy(policyId, values.propSlice(fields));

      setSubmitting(false);
      resetForm({ values });

      if (updated) {
        toast('The policy was updated');
        return;
      }

      errorToast('The policy was not updated');
    },
  });

  const query = useQuery([QueryKey.PolicyView, policyId], async () => getPolicyById(policyId));
  const { showLoader } = useQueryHelper(query);

  if (showLoader) {
    return;
  }

  return (
    <Form formHandle={formHandle} query={query}>
      <PageFeatureToast featureId="change-policy" can={canChangePolicy} />
      <FormSection title="Allow Access to Portal">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Enable or disable portal access for groups using this policy.
          </Grid>
          <Grid item xs={5}>
            <InputControl
              label="Portal Enabled"
              name="portal.enabled"
              type="switch"
              formHandle={formHandle}
              readonly={!canChangePolicy}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection title="Allowed Public Services">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Use the table below to allow or deny public services for this group. Users belonging to
            this group will only have access to public services listed on the right under "Allowed
            Services".
          </Grid>
          <Grid item xs={12}>
            <InputControl
              name="publicServices.enabled"
              label="Public Services"
              formHandle={formHandle}
              readonly={!canChangePolicy}
              type="public-service-acl"
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormButtons formHandle={formHandle} readonly={!canChangePolicy} />
    </Form>
  );
};

export default PolicyPublicServicesForm;
