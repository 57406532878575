import Card from '@/components/card';
import CardHeader from '@/components/card/card-header';
import CardRow from '@/components/card/card-row';
import Text from '@/components/text';
import { KendraMetrics } from '@/lib/models/context-provider';
import { Box, Stack } from '@mui/material';
import { FC } from 'react';

interface Props {
  metrics: KendraMetrics;
}

const KendraMetricsCard: FC<Props> = ({ metrics }) => {
  const { succeededDocsCount = 0, failedDocsCount = 0, totalDocsCount = 0 } = metrics;

  return (
    <Card>
      <CardHeader title="Document Metrics" icon="assessment" />
      <Box>
        <CardRow>
          <Stack direction="row" justifyContent="space-between">
            <Text bold>Scanned</Text>
            <Text>{succeededDocsCount}</Text>
          </Stack>
        </CardRow>
        <CardRow>
          <Stack direction="row" justifyContent="space-between">
            <Text bold>Failed</Text>
            <Text>{failedDocsCount}</Text>
          </Stack>
        </CardRow>
        <CardRow>
          <Stack direction="row" justifyContent="space-between">
            <Text bold>Total</Text>
            <Text>{totalDocsCount}</Text>
          </Stack>
        </CardRow>
      </Box>
    </Card>
  );
};

export default KendraMetricsCard;
