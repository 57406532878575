import { FC } from 'react';
import Chip from '../chip';
import { UserEventActionMap } from './helpers';
import { PolicyDecision } from '@/lib/models/user-event.model';

interface Props {
  action: PolicyDecision;
}

const ActionChip: FC<Props> = ({ action }) => {
  const { decision } = action;
  const { label, color } = UserEventActionMap.get(decision) || {
    label: 'unknown',
    color: 'default',
  };

  return <Chip label={String(label).toUpperCase()} color={color} size="small" />;
};

export default ActionChip;
