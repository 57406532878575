import Button from '@/components/button';
import { Box, Stack } from '@mui/material';
import FormMessage from '../form-message';
import { FC, useCallback } from 'react';
import { FormHandle } from '@/hooks/use-form-handle.hook';
import { FormikValues } from 'formik';
import useModal from '@/hooks/use-modal.hook';
import Text from '@/components/text';
import InlineToast from '@/components/toasts/inline';

interface Props {
  formHandle: FormHandle<FormikValues>;
  isNew?: boolean;
  onDelete?: (values: FormikValues) => void | Promise<void>;
  deleteName?: string;
  readonly?: boolean;
}

const FormButtons: FC<Props> = ({
  formHandle,
  isNew = false,
  onDelete,
  deleteName = '',
  readonly = false,
}) => {
  const {
    canCancel,
    canSubmit,
    handleSubmit,
    handleCancel,
    errors,
    validateOnChange,
    values,
    formMessage,
  } = formHandle;

  const canDelete = !isNew && Boolean(onDelete) && !readonly;
  const { openModal } = useModal();

  const handleDelete = () => {
    if (!canDelete) {
      return;
    }
    openModal('confirm', {
      title: `Delete - ${deleteName}`,
      content: (
        <>
          Are you sure you want to delete{' '}
          <Text inline bold>
            {deleteName}
          </Text>
          ? This action cannot be undone.
        </>
      ),
      onClose: (confirm: boolean) => {
        if (confirm) {
          onDelete!(values);
        }
      },
    });
  };

  const warnMessageRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      node.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const hasFormMessage = !!formMessage?.content;
  const hasErrors = !!errors?.length;

  if (readonly) {
    return null;
  }

  return (
    <Stack spacing={4} direction="row" alignItems="center" minHeight={60}>
      <Stack gap={1} direction="row">
        <Button
          label={isNew ? 'Create' : 'Save Changes'}
          disabled={!canSubmit}
          onClick={handleSubmit}
          type="submit"
        />
        <Button label="Cancel" disabled={!canCancel} onClick={handleCancel} type="cancel" />
      </Stack>

      <Box>
        {hasErrors && <FormMessage errors={errors} validateOnChange={validateOnChange} />}
        {!hasErrors && hasFormMessage && (
          <Box ref={warnMessageRef}>
            <InlineToast
              level={formMessage.level}
              message={formMessage.content}
              attention={!!formMessage.attention}
            />
          </Box>
        )}
      </Box>

      {canDelete && (
        <Box display="flex" justifyContent="flex-end" flexGrow={1}>
          <Button icon="delete" type="delete" label="Delete" onClick={() => handleDelete()} />
        </Box>
      )}
    </Stack>
  );
};

export default FormButtons;
