import Button from '@/components/button';
import Icon from '@/components/icon';
import Text from '@/components/text';
import useToast from '@/hooks/use-toast.hook';
import { OrgModel } from '@/lib/models/org.model';
import { patchOrg } from '@/lib/services/org.service';
import { Box, Stack, Theme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import PreviewCard from './preview-card';
import InlineToast from '@/components/toasts/inline';

export type FileWithPreview = FileWithPath & { preview: string };

interface Props {
  organization: OrgModel;
  readonly: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  uploadForm: {
    width: '50%',
    boxSizing: 'border-box',
    marginRight: 100,
    paddingRight: 16,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  uploader: {
    '& .dropzone': {
      cursor: 'pointer',
      padding: 15,
      backgroundColor: theme.palette.grey[50],
      borderRadius: theme.shape.borderRadius,
      border: `1px dashed ${theme.palette.grey[200]}`,
      color: theme.palette.grey[700],
    },
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 2,
  },
  thumb: {
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: 4,
    height: 48,
    boxSizing: 'border-box',
    '& img': {
      width: 32,
      height: 32,
    },
  },
  thumbPlaceholder: {
    backgroundColor: theme.palette.grey[200],
    width: 42,
    height: 42,
    borderRadius: theme.shape.borderRadius,
  },
  imagePlaceholder: {
    border: `1px dashed ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    height: 32,
    width: 32,
  },
  faviconImagePreview: {
    '& img': {
      height: 16,
      width: 16,
    },
  },
  titleForm: {
    marginTop: 8,
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
}));

const MAX_FILE_SIZE_KB = 100;
const IMAGE_HEIGHT_PX = 16;
const IMAGE_WIDTH_PX = 16;

const OrgFaviconForm: FC<Props> = ({ organization, readonly }) => {
  const styles = useStyles();
  const { favicon } = organization.branding;

  const { toast, errorToast } = useToast();
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [uploadError, setUploadError] = useState('');
  const [image, setImage] = useState(favicon || '');

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': [],
    },
    onDrop: (acceptedFiles: FileWithPath[]) => {
      setUploadError('');
      if (!acceptedFiles.length) {
        return;
      }

      const file = acceptedFiles[0];

      if (file.size > MAX_FILE_SIZE_KB * 1000) {
        console.error('image invalid via size', file);
        setUploadError(`Please limit the image to a maximum size of ${MAX_FILE_SIZE_KB}kb`);
        setFiles([]);
        return;
      }

      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        setFiles([Object.assign(file, { preview: URL.createObjectURL(file) })]);
      };
    },
  });

  const handleAccept = () => {
    if (!files.length) {
      return;
    }

    const file = files[0];

    const reader = new FileReader();

    reader.onload = (loadEvent) => {
      const dataURL = loadEvent?.target?.result;
      if (!dataURL) {
        setUploadError('There was an error uploading your file. Please try again.');
        setFiles([]);
        return;
      }

      setUploadError('');
      setFiles([]);
      handleUploadImage(String(dataURL));
    };

    reader.readAsDataURL(file);
  };

  const handleUploadImage = (dataURL: string) => {
    const updatedOrg = new OrgModel(organization._props);
    updatedOrg.branding.favicon = dataURL;

    patchOrg(updatedOrg).then(({ patched, error }) => {
      if (patched) {
        setImage(dataURL);
        toast('The favicon was updated');
        return;
      }

      errorToast(error || 'The favicon was not updated');
    });
  };

  const handleRemoveImage = () => {
    const updatedOrg = new OrgModel(organization._props);
    updatedOrg.branding.favicon = '';

    patchOrg(updatedOrg).then(({ patched, error }) => {
      if (patched) {
        setImage('');
        toast('The favicon was removed');
        return;
      }

      errorToast(error || 'The favicon was not removed');
    });
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(({ preview }) => URL.revokeObjectURL(preview));
  }, [files]);

  const canUpload = files.length === 1;
  const hasError = Boolean(uploadError);
  const hasFiles = !!files.length;
  const hasImage = Boolean(image);

  return (
    <>
      <Box marginBottom={6}>
        The Organization Favicon will appear in browser tabs, address bars, and bookmark lists.
      </Box>
      <Stack direction="row">
        {!readonly && (
          <Box className={styles.uploadForm}>
            <Box className={styles.uploader}>
              <Box mb={2}>
                <Text bold>Upload New Favicon Image</Text>
              </Box>
              <Box
                {...getRootProps({ className: 'dropzone' })}
                height={200}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <input {...getInputProps()} />
                <Box mr={1}>
                  <Icon name="upload" size="large" />
                </Box>
                <Box>Drag and drop an image file here, or click to choose a file</Box>
              </Box>

              <Stack mt={3} gap={1}>
                <Text bold size="small">
                  Upload Preview
                </Text>
                <Box className={styles.thumbsContainer}>
                  {files.map(({ name, preview }) => (
                    <Box className={styles.thumb} key={name}>
                      <img
                        src={preview}
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                          URL.revokeObjectURL(preview);
                        }}
                      />
                    </Box>
                  ))}
                  {!hasFiles && (
                    <Box className={styles.thumbPlaceholder}>
                      <Box className="inner"></Box>
                    </Box>
                  )}
                </Box>
              </Stack>
              <Stack mt={3} gap={1}>
                <Text bold size="small">
                  Upload Size and Format
                </Text>
                <Text size="small">
                  Please upload only PNG files with a maximum size of {MAX_FILE_SIZE_KB}kb.
                </Text>
              </Stack>
              <Stack justifyContent="space-between" direction="row" mt={6}>
                <Button label="upload" disabled={!canUpload} onClick={handleAccept} type="submit" />

                {!readonly && (
                  <Button
                    label="remove favicon"
                    type="delete"
                    disabled={!hasImage}
                    onClick={handleRemoveImage}
                  />
                )}
              </Stack>

              {hasError && (
                <Box mt={2}>
                  <InlineToast message={uploadError} level="error" />
                </Box>
              )}
            </Box>
          </Box>
        )}
        <Box width="50%">
          <PreviewCard
            imageHeight={IMAGE_HEIGHT_PX}
            imageWidth={IMAGE_WIDTH_PX}
            maxFileSize={MAX_FILE_SIZE_KB}
            allowedFiletypes={['PNG']}
          >
            <Stack gap={4}>
              <Box>
                <Text size="small">
                  The favicon will be sized to {IMAGE_WIDTH_PX}px by {IMAGE_HEIGHT_PX}px, but may
                  appear as large as 96px by 96px, in some cases.
                </Text>
              </Box>

              <Box>
                {hasImage && (
                  <Box className={styles.faviconImagePreview}>
                    <img src={image} />
                  </Box>
                )}
                {!hasImage && <Box className={styles.imagePlaceholder}></Box>}
              </Box>
            </Stack>
          </PreviewCard>
        </Box>
      </Stack>
    </>
  );
};

export default OrgFaviconForm;
