import { FC } from 'react';
import Card from '@/components/card';
import { Box, Stack } from '@mui/material';
import CardHeader from '../card/card-header';
import NoneChip from '../chip/none-chip';
import { ContextProviderModel } from '@/lib/models/context-provider/context-provider.model';
import ContextProviderButton from '../context-provider-button';

interface Props {
  contexts: ContextProviderModel[];
}

const ContextProvidersCard: FC<Props> = ({ contexts }) => {
  const hasContexts = !!contexts.length;

  return (
    <Card>
      <CardHeader title="Context Sources" icon="doc-search" />
      <Box>
        {!hasContexts && <NoneChip />}
        {hasContexts && (
          <Stack gap={1} mt={2}>
            {contexts.map((context) => {
              return <ContextProviderButton context={context} key={context.id} />;
            })}
          </Stack>
        )}
      </Box>
    </Card>
  );
};

export default ContextProvidersCard;
