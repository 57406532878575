import { useMemo } from 'react';
import { useQuery } from 'react-query';
import useToast from '@/hooks/use-toast.hook';
import useFormHandle from '@/hooks/use-form-handle.hook';
import { PolicyModel } from '@/lib/models/policy.model';
import FormSection from '@/components/form/form-section';
import InputControl from '@/components/form/input-control';
import FormButtons from '@/components/form/form-buttons';
import useQueryHelper from '@/hooks/use-query-helper';
import { getOrgPolicy, patchPolicy } from '@/lib/services/policy.service';
import { getAllProviders } from '@/lib/services/context-provider.service';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';

const fields = ['contextDataSources'];
const formModel = new PolicyModel();

const ContextProvidersForm = () => {
  const { errorToast, toast } = useToast();
  const { canChangePolicy } = useFeature();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const updated = await patchPolicy(values.id!, values.propSlice(fields));
      setSubmitting(false);
      resetForm({ values });

      if (updated) {
        toast('The organization policy was updated');
        return;
      }

      errorToast('The organization policy was not updated');
    },
  });

  const orgPolicyQuery = useQuery([QueryKey.OrgPolicyView], async () => getOrgPolicy());

  const contextProviderQuery = useQuery([QueryKey.ContextProvidersList], async () =>
    getAllProviders()
  );
  const { data: providers } = contextProviderQuery;
  const { showLoader: policyLoader } = useQueryHelper(orgPolicyQuery);
  const { showLoader: providerLoader } = useQueryHelper(contextProviderQuery);

  const providerOptions = useMemo(() => {
    if (!providers?.rows) {
      return [];
    }
    return providers.rows.map(({ id, name }) => ({
      value: id!,
      label: name,
    }));
  }, [providers]);

  if (policyLoader || providerLoader) {
    return;
  }

  return (
    <Form formHandle={formHandle} query={orgPolicyQuery}>
      <PageFeatureToast featureId="change-policy" can={canChangePolicy} />
      <FormSection title="Context Sources Assignment">
        <InputControl
          name="contextDataSources.enabled"
          label="Context"
          formHandle={formHandle}
          readonly={!canChangePolicy}
          type="list-toggle"
          leftTitle="Available Context Sources"
          rightTitle="Assigned Context Sources"
          options={providerOptions}
        />
      </FormSection>

      <FormButtons formHandle={formHandle} readonly={!canChangePolicy} />
    </Form>
  );
};

export default ContextProvidersForm;
