import { object } from 'yup';
import { BaseModel } from './base.model';
import { JsonObject, UiOption } from '../helpers';
import { capitalize, flatten } from 'lodash';

type UserEventRole = 'context' | 'user' | 'assistant';

type SdPosition = { startPos: number; endPos: number; type: string; score: number };

type SensitiveData = { detected: boolean; detections: SdPosition[][] };

type IntentScore = { name: string; confidence: number; actionName: string; departmentName: string };

type UserEventIntent = {
  requestViolation: boolean;
  responseViolation: boolean;
  request: IntentScore[];
  response: IntentScore[];
};

export type UserClaim = {
  userId: string;
  email: string;
  name: string;
  role: string;
  orgId: string;
  groups: string[];
  spUserId: string;
};

export type ClientAddr = {
  remoteAddr: string;
  xForwardedFor: string;
  xRealIp: string;
};

export type PolicyDecision = {
  decision: UserEventAction;
  label: string;
  code: number;
  codeLabel: string;
  violations: {
    sensitiveData: boolean;
    intent: boolean;
    access: boolean;
  };
  isGroupPolicy: boolean;
  groupId: string;
};

// we should never see 0,3,6
export enum UserEventAction {
  CONTINUE = 0,
  ALLOW = 1,
  BLOCK = 2,
  DROP = 3,
  PORTAL = 4,
  REROUTE = 5,
  PASSTHROUGH = 6,
  ERROR = 7,
  DOWNTIME = 8,
  HTTPERROR = 9,
  AUTH = 10,
  INTERCEPTAUTH = 11,
  INLINEBLOCK = 12,
  REDACT = 13,
}

 type ShortcutInputType =
  | 'text'
  | 'textarea'
  | 'range'
  | 'switch'
  | 'select'
  | 'select-multi';


 type ShortcutValue = string | string[];

 type ShortcutStep = {
  name: string;
  title: string;
  description: string;
  inputType: ShortcutInputType;
  options?: UiOption[];
};

  export type ShortcutMessage = {
  id: string;
  name: string;
  instructions: string;
  steps: ShortcutStep[];
  values: ShortcutValue[];
};

export type ContextDetails = {
  name: string;
  origin: 'user' | 'enterprise';
  source: string;
  purpose?: string;
  summary?: string;
  type: string; // @todo file|context-datasource?
};

type UserEventMessage = {
  requestId: string;
  role: UserEventRole;
  content: string;
  origContent?: string;
  timestamp: number;
  shortcut: ShortcutMessage
  contextDetails: ContextDetails;
  violations: {
    sensitiveData: boolean;
    intent: boolean;
    access: boolean;
  };
  sensitiveDataDetections: SdPosition[];
};

type FirewalDecision = {
  action: string;
  target: { name: string };
};

export class UserEventModel extends BaseModel {
  asyncId: string;
  clientIp: ClientAddr;
  code: number;
  conversationId: string;
  endTime: number;
  error: string;
  firewallDecision: FirewalDecision;
  intent: UserEventIntent;
  messages: {
    requestId: string;
    conversation: UserEventMessage[];
    request: UserEventMessage[];
    response: UserEventMessage[];
  };
  orgId: string;
  parentTraceId: string;
  policyDecision: PolicyDecision;
  requestType: string;
  requestUrl: string;
  responseType: string;

  sensitiveData: {
    request: SensitiveData;
    response: SensitiveData;
  };
  serviceName: string;
  serviceId: string;
  startTime: number;
  timestamp: number;
  traceId: string;
  requestId: string;
  userClaim: UserClaim;
  userId: string;

  static schema = object({});

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof UserEventModel, unknown>;

    this.asyncId = (data.asyncId as string) || '';
    this.clientIp = (data.clientIp as ClientAddr) || {
      remoteAddr: '',
      xForwardedFor: '',
      xRealIp: '',
    };
    this.code = (data.code as number) || 0;
    this.conversationId = (data.conversationId as string) || '';
    this.endTime = (data.endTime as number) || 0;
    this.error = (data.error as string) || '';
    this.firewallDecision = (data.firewallDecision as FirewalDecision) || {
      action: '',
      target: { name: '' },
    };

    this.intent = (data.intent as UserEventIntent) || {
      requestViolation: false,
      responseViolation: false,
      request: [],
      response: [],
    };

    this.messages = (data.messages as {
      requestId: string;
      conversation: UserEventMessage[];
      request: UserEventMessage[];
      response: UserEventMessage[];
    }) || { requestId: '', conversation: [], request: [], response: [] };

    this.orgId = (data.orgId as string) || '';
    this.parentTraceId = (data.parentTraceId as string) || '';
    this.policyDecision = (data.policyDecision as PolicyDecision) || {
      decision: 0,
      label: '',
      code: 0,
      codeLabel: '',
      violations: {
        sensitiveData: false,
        intent: false,
        access: false,
      },
      isGroupPolicy: false,
      groupId: '',
    };
    this.requestType = (data.requestType as string) || '';
    this.requestUrl = (data.requestUrl as string) || '';
    this.responseType = (data.responseType as string) || '';

    this.sensitiveData = (data.sensitiveData as {
      request: SensitiveData;
      response: SensitiveData;
    }) || {
      request: { detected: false, detections: [] },
      response: { detected: false, detections: [] },
    };

    this.traceId = (data.traceId as string) || '';
    this.requestId = (data.requestId as string) || '';
    this.startTime = (data.startTime as number) || 0;
    this.timestamp = (data.timestamp as number) || 0;
    this.serviceName = (data.serviceName as string) || '';
    this.serviceId = (data.serviceId as string) || '';

    this.userClaim = {
      userId: '',
      name: '',
      email: '',
      orgId: '',
      role: '',
      groups: [],
      spUserId: '',
    };

    if (data.userClaim) {
      this.userClaim = UserEventModel.makeUserClaim(data.userClaim as Record<string, unknown>);
    }

    this.userId = (data.userId as string) || '';
  }

  static makeUserClaim(data: Record<string, unknown>): UserClaim {
    const { name, email, org_id, role, user_id, sp_user_id, groups } = data;
    return {
      name: name as string,
      email: email as string,
      role: role as string,
      orgId: org_id as string,
      userId: user_id as string,
      groups: groups as string[],
      spUserId: sp_user_id as string,
    };
  }

  get requestHost(): string | null {
    try {
      const requestURL = new URL(this.requestUrl);
      return requestURL.host;
    } catch {
      return '';
    }
  }

  get highIntent(): IntentScore | null {
    let highIntent: IntentScore | null = null;

    if (this.intent.request?.length) {
      highIntent = this.intent.request?.reduce((high, current) =>
        current.confidence > high.confidence ? current : high
      );
    }

    if (!highIntent && this.intent.response?.length) {
      highIntent = this.intent.response?.reduce((high, current) =>
        current.confidence > high.confidence ? current : high
      );
    }

    if (!highIntent) {
      return null;
    }

    // If action or department are not defined, try to parse them out of the name field
    if ((!highIntent.actionName || !highIntent.departmentName) && highIntent.name) {
      const [, departmentName, actionName] = String(highIntent.name).split('/');
      if (departmentName && !highIntent.departmentName) {
        highIntent.departmentName = departmentName;
      }

      if (actionName && !highIntent.actionName) {
        highIntent.actionName = actionName;
      }
    }

    return {
      ...highIntent,
      departmentName: capitalize(highIntent.departmentName),
      actionName: capitalize(highIntent.actionName),
    };
  }

  get intentLabel(): string {
    if (!this.highIntent) {
      return '';
    }

    const { actionName, departmentName } = this.highIntent;

    return [departmentName, actionName]
      .filter((v) => v)
      .map(capitalize)
      .join(' / ');
  }

  get violations(): string[] {
    const {
      intent = false,
      access = false,
      sensitiveData = false,
    } = this.policyDecision?.violations || {};
    const violations: string[] = [];

    if (intent) {
      violations.push('intent');
    }

    if (access) {
      violations.push('access');
    }

    if (sensitiveData) {
      violations.push('pii');
    }

    return violations;
  }

  get lastUserMessage(): string | null {
    const userMessages = this.messages.request.filter(({ role }) => role === 'user');
    return userMessages.pop()?.content || null;
  }

  get sensitiveDataTypes(): string[] {
    const detections = [
      ...(this.sensitiveData.request.detections || []),
      ...(this.sensitiveData.response.detections || []),
    ];
    return flatten(detections.map((detection) => detection.map(({ type }) => type)));
  }

  // for historic reasons, some requests do not have a traceId
  get compTraceId(): string {
    return this.traceId || this.requestId;
  }
}
