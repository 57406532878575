import Card from '@/components/card';
import Icon from '@/components/icon';
import Text from '@/components/text';
import { pluralize } from '@/lib/helpers';
import { Grid, Stack } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  imageWidth: number;
  imageHeight: number;
  maxFileSize: number;
  allowedFiletypes?: string[];
}

const PreviewCard: FC<Props> = ({
  children,
  imageHeight,
  imageWidth,
  maxFileSize,
  allowedFiletypes = ['JPG', 'PNG'],
}) => {
  return (
    <Stack gap={6}>
      <Card
        title={
          <Stack direction="row" gap={1}>
            <Icon name="image" />
            <Text>Preview</Text>
          </Stack>
        }
      >
        {children}
      </Card>
      <Grid container spacing={1} width="70%">
        <Grid item xs={6}>
          <Text bold>Allowed file {pluralize(allowedFiletypes.length, 'type')}</Text>
        </Grid>
        <Grid item xs={6}>
          {allowedFiletypes.join(', ')}
        </Grid>
        <Grid item xs={6}>
          <Text bold>Maximum file size</Text>
        </Grid>
        <Grid item xs={6}>
          {maxFileSize}kb
        </Grid>
        <Grid item xs={6}>
          <Text bold>Image width</Text>
        </Grid>
        <Grid item xs={6}>
          {imageWidth}px
        </Grid>

        <Grid item xs={6}>
          <Text bold>Image height</Text>
        </Grid>
        <Grid item xs={6}>
          {imageHeight}px
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PreviewCard;
