import { FC } from 'react';
import Card from '@/components/card';
import { Box, Stack } from '@mui/material';
import CardHeader from '../card/card-header';
import NoneChip from '../chip/none-chip';
import { PolicyModel } from '@/lib/models/policy.model';
import PolicyButton from '../policy-button';

interface Props {
  policies: PolicyModel[];
}

const PoliciesCard: FC<Props> = ({ policies }) => {
  const hasPolicies = !!policies.length;

  return (
    <Card>
      <CardHeader title="Policies" icon="policy" />
      <Box>
        {!hasPolicies && <NoneChip />}
        {hasPolicies && (
          <Stack gap={1} mt={2}>
            {policies.map((policy) => {
              return <PolicyButton policy={policy} key={policy.id} />;
            })}
          </Stack>
        )}
      </Box>
    </Card>
  );
};

export default PoliciesCard;
