import { FC } from 'react';
import { Box } from '@mui/material';
import FormSection from '@/components/form/form-section';
import { useQuery } from 'react-query';
import useQueryHelper from '@/hooks/use-query-helper';
import { getOrg } from '@/lib/services/org.service';
import OrgLogoUploadForm from './org-logo-upload-form';
import PortalBadgeForm from './portal-badge-form';
import OrgFaviconForm from './org-favicon-form';
import { QueryKey } from '@/lib/query-client';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';

const BrandingForm: FC = () => {
  const { canChangeBranding } = useFeature();

  const query = useQuery([QueryKey.OrgView], async () => getOrg());

  const { data } = query;
  const { showLoader } = useQueryHelper(query);

  if (showLoader) {
    return null;
  }

  return (
    <Box>
      <PageFeatureToast featureId="change-branding" can={canChangeBranding} />
      <FormSection title="Organization Logo">
        <OrgLogoUploadForm organization={data!} readonly={!canChangeBranding} />
      </FormSection>

      <FormSection title="Organization Favicon">
        <OrgFaviconForm organization={data!} readonly={!canChangeBranding} />
      </FormSection>

      <FormSection title="Portal Assistant Badge">
        <PortalBadgeForm organization={data!} readonly={!canChangeBranding} />
      </FormSection>
    </Box>
  );
};

export default BrandingForm;
