import { firebaseAuth } from './firebase.service';
import { User, onAuthStateChanged, signInWithCustomToken } from 'firebase/auth';
import { isProd } from '../environment';
import axios, { AxiosError } from 'axios';
import { JsonObject } from '../helpers';
import { getUnixTime } from 'date-fns';
import { getUrlQueryParam, removeUrlQueryParam } from '../url-helpers';
import { ApiResponse, handleResponse } from '.';
import { post } from './sp-api.service';

export type AuthRole = 'org:owner' | 'org:admin' | 'org:auditor';

export const AuthRoleLabels: Record<AuthRole, string> = {
  'org:owner': 'Owner',
  'org:admin': 'Admin',
  'org:auditor': 'Auditor',
};

export type AuthPermission = 'manage-admins' | 'read' | 'write';

export type AuthUser = User & {
  token: string;
  orgId: string;
  role: AuthRole | null;
  assumed: boolean;
};

class AuthProviderModel {
  user: AuthUser | null = null;

  get token(): string {
    return this.user?.token || '';
  }

  get orgId(): string {
    return this.user?.orgId || '';
  }
}

export const AuthProvider = new AuthProviderModel();

const axiosClient = axios.create({
  baseURL: '',
  timeout: 10000,
  withCredentials: false,
  responseType: 'json',
  headers: {
    'X-Requested-By': 'surepath',
  },
});

axiosClient.interceptors.request.use((config) => {
  if (AuthProvider.token) {
    config.headers['Surepath-Authorization'] = `Bearer ${AuthProvider.token}`;
  }

  return config;
});

const get = async (path: string, params?: JsonObject): Promise<ApiResponse> => {
  return axiosClient.get(path, { params }).then(handleResponse);
};

export const userCan = (user: AuthUser | null | undefined, perm: AuthPermission): boolean => {
  const role = user?.role;
  if (!role) {
    return false;
  }

  if (role === 'org:owner') {
    return true;
  }

  switch (perm) {
    case 'read':
      return ['org:admin', 'org:auditor'].includes(role);
    case 'manage-admins':
      return false;
    case 'write':
      return ['org:admin'].includes(role);
  }
};

const getBaseUrl = (subdomain: 'auth' | 'admin' | 'partner'): string => {
  let domain = `${subdomain}.surepath.ai`;

  if (!isProd()) {
    const [, environment] = window.location.hostname.split('.');

    if (['dev', 'stage', 'local'].includes(environment)) {
      domain = `${subdomain}.${environment}.surepath.ai`;
    }
  }

  return `https://${domain}`;
};

const getAuthBaseUrl = (): string => getBaseUrl('auth');

const getPartnerBaseUrl = (): string => getBaseUrl('partner');

export const getAuthNoAccessUrl = (): string => {
  return `${getAuthBaseUrl()}/splash?action=admin-access`;
};

export const getAuthRedirectUrl = (fromAuthSite?: boolean): string => {
  // user was just redirected here from auth? don't redirect them back
  const fas = typeof fromAuthSite === 'boolean' ? fromAuthSite : getFromAuthSite();
  if (fas) {
    return '/error?code=500';
  }

  const redirectDest = encodeURIComponent(window.location.origin);
  return `${getAuthBaseUrl()}/login?redirect_to=${redirectDest}`;
};

export const getFromAuthSite = (): boolean => {
  const fromAuthSite = getUrlQueryParam('fas');
  removeUrlQueryParam('fas');
  return !!fromAuthSite;
};

export const getAssumeFlag = (): boolean => {
  const assumeFlag = getUrlQueryParam('assume');
  removeUrlQueryParam('assume');
  return !!assumeFlag;
};

export const signOut = async (redirect = true): Promise<boolean> => {
  try {
    await firebaseAuth.signOut();

    if (redirect) {
      window.location.href = `${getAuthBaseUrl()}/logout?redirect_to=${window.location.origin}`;
    }
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getCurrentUser = async (skipFirebase = false): Promise<AuthUser | null> => {
  const fbAuthUser = skipFirebase ? null : await getCurrentFirebaseUser(true);

  if (fbAuthUser) {
    console.log('auth from fb-ls');
    AuthProvider.user = fbAuthUser;
    return fbAuthUser;
  }

  const spAuthUser = await getCurrentTokenUser();

  if (spAuthUser) {
    console.log('auth from sp-token');
    AuthProvider.user = spAuthUser;
    return spAuthUser;
  }

  return null;
};

/*
 * Ensure that the JWT token is refreshed periodically so that other, non-firebase services can always
 * have an up-to-date token. Note that while Firebase will lazy-refresh the token for itself, other
 * services (like AtlasDataAPI) need a synchronous way of referencing an up-to-date token.
 *
 * While it seems as though you can listen for auth token expiration using onIdTokenChanged, this handler does not
 * actually fire when or before that happens:
 * https://github.com/firebase/firebase-js-sdk/issues/2985
 * https://github.com/firebase/firebase-js-sdk/blob/e9ff107eedbb9ec695ddc35e45bdd62734735674/packages/auth/src/core/index.ts#L136
 *
 */
export const observeTokenChange = () => {
  firebaseAuth.onIdTokenChanged((user: User) => {
    // @todo org id or role changed? throw up a modal

    console.log('local auth change', user?.uid);
    if (!user) {
      AuthProvider.user = null;
      return;
    }

    getAuthUser(user).then((authUser) => {
      /*
       * This condition checks for an edge case where a partner or root user may somehow attempt to
       * assume a role into multiple orgs in the same browser, at the same time. In that case, the
       * second assume attempt would overwrite firebase local credentials causing both admin tabs
       * to "switch" to the second assumed org.
       */
      if (AuthProvider.orgId && authUser?.orgId && AuthProvider.orgId !== authUser.orgId) {
        console.warn('detected org change from auth observer, reloading...');
        window.location.reload();
        return;
      }

      AuthProvider.user = authUser;
    });
  });

  const intMinutes = 1000 * 60 * 8;

  setInterval(() => {
    firebaseAuth.currentUser?.getIdTokenResult().then((result) => {
      const expTime = result?.claims?.exp;
      if (!expTime) {
        return;
      }

      const minuteDiff = (Number(expTime) - getUnixTime(new Date())) / 60;

      if (minuteDiff < 20) {
        firebaseAuth.currentUser?.getIdToken(true);
      }
    });
  }, intMinutes);
};

const getCurrentTokenUser = async (): Promise<AuthUser | null> => {
  let spAccessToken = '',
    spTenantId = '';

  try {
    const response = await get('/auth/status');

    const { accessToken, tenantId, assumedCustomToken } =
      (response as { accessToken: string; tenantId: string; assumedCustomToken: string }) || {};

    if (!tenantId) {
      return null;
    }

    spTenantId = tenantId;

    // role assumption response
    if (assumedCustomToken) {
      spAccessToken = assumedCustomToken;
      // normal login
    } else if (accessToken) {
      spAccessToken = accessToken;
    }
  } catch (err) {
    const axiosError = err as AxiosError;
    // cookie is probably just missing
    if (axiosError.response?.status === 400) {
      return null;
    }
    console.error(err);
    return null;
  }

  if (!spAccessToken || !spTenantId) {
    return null;
  }

  firebaseAuth.tenantId = spTenantId;

  const result = await signInWithCustomToken(firebaseAuth, spAccessToken);

  if (!result?.user) {
    return null;
  }

  return getAuthUser(result.user);
};

const getCurrentFirebaseUser = async (forceRefresh?: boolean): Promise<AuthUser | null> => {
  return new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (!user) {
        resolve(null);
        return;
      }

      unsubscribe();

      getAuthUser(user, forceRefresh).then(resolve);
    });
  });
};

const isValidCalimValue = (claimVal: string | null | undefined): boolean =>
  !!claimVal && claimVal !== 'none';

/*
 * Given a Firebase User, get their parsed JWT and then validate that important
 * authentication parameters are present. Return an AuthUser to act as the primary
 * interface for the auth service and the rest of the application, as opposed to directly
 * handling the Firebase User object.
 */
const getAuthUser = async (user: User, forceRefresh = false): Promise<AuthUser | null> => {
  const userWithToken = user as User & { accessToken: string };

  // user missing critical info
  if (!userWithToken.tenantId || !userWithToken.accessToken) {
    console.error('user missing tenant id or access token');
    return null;
  }

  const result = await user.getIdTokenResult(forceRefresh);
  const { claims, token } = result || {};

  if (!claims || !token) {
    console.error('user missing claims or token');
    return null;
  }

  const { org_id, role, assumed } = claims as { org_id: string; role: string; assumed: boolean };

  /*
   * Rather than returning null here, simply warn and then return an AuthUser
   * object which does not have read permissions. This way, the user can be
   * redirected to the admin-no-access page, rather than being handled as if
   * they are not logged in.
   */
  if (!isValidCalimValue(org_id) || !isValidCalimValue(role)) {
    console.warn('user missing org_id/role', claims);
  }

  return {
    ...user,
    orgId: org_id || '',
    role: (role as AuthRole) || null,
    token,
    assumed: !!assumed,
  };
};

export const deactivateAssumeRole = async (): Promise<boolean> => {
  const response = await post('/auth/assume/return', {});

  if ((response as JsonObject)?.status !== 'ok') {
    return false;
  }

  // @todo should we logout of firebase here? hitting the return endpoint nukes refresh tokens...

  window.location.href = getPartnerBaseUrl();

  return true;
};
