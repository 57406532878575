import { BaseMeta, BaseModel } from './base.model';
import { JsonObject, UiOption } from '../helpers';
import { ChipColor } from '@/components/chip';
import { IconType } from '@/components/icon';

export type PsSupportLevel = 'access' | 'intercept';

export type PsSupport = {
  level: PsSupportLevel;
  state: {
    disabled: boolean;
    code: number;
  };
};

export type PsSignal = {
  value: string | boolean;
  excerpts: string[];
  description: string;
};

export type PsSignalSet = {
  companyCountry: PsSignal;
  trainOnUserData: PsSignal;
  noModeration: PsSignal;
  confidence: number;
};

export type PsRisk = {
  base: {
    score: number;
    level: string;
  };
  weighted: {
    score: number;
    level: string;
  };
};

export const SignalOpts: UiOption[] = [
  { label: 'Trains on User Data', value: 'trainOnUserData' },
  { label: 'Unmoderated use', value: 'noModeration' },
];

export type PublicServiceMeta = BaseMeta & {
  name: string;
  image: string;
  description: string;
  supportLevel: string;
  risk: PsRisk;
  riskColor: ChipColor;
};

export class PublicServiceModel extends BaseModel {
  type: string;
  name: string;
  description: string;
  image: string;
  hosts: string[];
  unauthenticatedHosts: string[];
  website: string;
  companyName: {
    value: string;
    excerpts: string[];
  };
  support: PsSupport;
  signals: PsSignalSet;
  risk: PsRisk;
  state: {
    disabled: boolean;
    code: number;
  };

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof PublicServiceModel, unknown>;

    this.type = (data.type as string) || '';
    this.name = (data.name as string) || '';
    this.description = (data.description as string) || '';
    this.image = (data.image as string) || '';
    this.hosts = (data.hosts as string[]) || [];
    this.unauthenticatedHosts = (data.unauthenticatedHosts as string[]) || [];
    this.website = (data.website as string) || '';
    this.companyName = (data.companyName as { value: string; excerpts: string[] }) || {
      value: '',
      excerpts: [],
    };
    this.support = (data.support as PsSupport) || {
      level: 'access',
      state: { disabled: false, code: 0 },
    };
    this.signals = (data.signals as PsSignalSet) || {
      companyCountry: {
        value: '',
        excerpts: [],
        description: '',
      },
      trainOnUserData: {
        value: '',
        excerpts: [],
        description: '',
      },
      noModeration: {
        value: '',
        excerpts: [],
        description: '',
      },
      confidence: 0,
    };
    this.risk = (data.risk as PsRisk) || {
      base: { level: 'LOW', score: 0 },
      weighted: { level: 'LOW', score: 0 },
    };
    this.state = (data.state as { disabled: boolean; code: number }) || {
      disabled: false,
      code: 0,
    };
  }

  get activeSignalLabels(): string[] {
    const labels: string[] = [];

    if (this.signals.noModeration?.value) {
      labels.push(
        (SignalOpts.find(({ value }) => value === 'noModeration')?.label as string) || ''
      );
    }

    if (this.signals.trainOnUserData?.value) {
      labels.push(
        (SignalOpts.find(({ value }) => value === 'trainOnUserData')?.label as string) || ''
      );
    }

    return labels;
  }

  get riskColor(): ChipColor {
    if (this.risk.base.level === 'MEDIUM') {
      return 'warning';
    }
    if (this.risk.base.level == 'HIGH') {
      return 'error';
    }

    return 'success';
  }

  get confidenceOption(): { label: string; icon: IconType } {
    if (this.signals.confidence === 1) {
      return { label: 'MEDIUM', icon: 'check' };
    }

    return this.signals.confidence > 1
      ? { label: 'HIGH', icon: 'arrow-double-up' }
      : { label: 'LOW', icon: 'arrow-double-down' };
  }

  get supportLevel(): string {
    if (this.support.level === 'access') {
      return 'access control';
    }

    if (this.support.level === 'intercept') {
      return 'prompt intercept';
    }

    return this.support.level || '';
  }

  get meta(): PublicServiceMeta {
    return {
      id: this.id || '',
      name: this.name,
      image: this.image,
      description: this.description,
      supportLevel: this.supportLevel,
      risk: this.risk,
      riskColor: this.riskColor,
    };
  }
}
