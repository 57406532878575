import { userCan } from '@/lib/services/auth.service';
import useApp from './use-app.hook';
import useAppData from './use-app-data.hook';
import { OrgBasicInfo, OrgMode } from '@/lib/models/org.model';
import { AppDataQueryKey } from '@/lib/query-client';
import { useCallback, useMemo } from 'react';
import { has } from 'lodash';
import useQueryHelper from './use-query-helper';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import { RagStatus } from '@/lib/services/context-provider.service';

interface FeatureFlagSet {
  canChangeConnector: (connector?: ConnectorModel) => boolean;
  canViewConnectorArn: boolean;
  canChangePrivateModels: boolean;
  canChangeAuth: boolean;
  canChangeSync: boolean;
  canChangePolicy: boolean;
  canManageAdmins: boolean;
  canChangeGroups: boolean;
  canChangeUsers: boolean;
  canViewConversations: boolean;
  canChangeCustomMessage: boolean;
  canChangeBranding: boolean;
  canChangeContextProviders: boolean;
  orgMode: OrgMode;
  ragStatus: RagStatus;
  getTooltip: (feature: FeatureId) => string;
  hasFeature: (feature: string) => boolean;
  loading: boolean;
}

export type FeatureFlag = (keyof FeatureFlagSet)[];

export type FeatureId =
  | 'change-connector'
  | 'add-connector'
  | 'change-private-model'
  | 'add-private-model'
  | 'change-sync'
  | 'change-auth'
  | 'change-policy'
  | 'add-group'
  | 'add-user'
  | 'view-conversation'
  | 'add-policy'
  | 'change-custom-message'
  | 'change-branding'
  | 'add-context-provider'
  | 'change-context-provider';

const useFeature = (): FeatureFlagSet => {
  const { user } = useApp();

  const query = useAppData<OrgBasicInfo | null>(AppDataQueryKey.OrgBasicInfo);
  const { data: orgBasicInfo } = query;
  const { showLoader: orgLoading } = useQueryHelper(query);

  const ragQuery = useAppData<RagStatus | null>(AppDataQueryKey.OrgRagStatus);
  const { data: ragStatus } = ragQuery;
  const { showLoader: ragLoading } = useQueryHelper(ragQuery);

  const assumeActive = !!user?.assumed;
  const ragActive = ragStatus === 'active';
  const orgMode = orgBasicInfo?.mode || 'platform';
  const canWrite = userCan(user, 'write');
  const isAuditor = user?.role === 'org:auditor';
  const isPlatform = orgMode === 'platform';
  const isDiscovery = orgMode === 'discovery';
  const isTrial = orgMode === 'trial';
  const canManageAdmins = userCan(user, 'manage-admins');

  const flags = useMemo(
    () => ({
      canViewConnectorArn: isPlatform,
      canChangePrivateModels: canWrite && isPlatform,
      canChangeAuth: canWrite && isPlatform,
      canChangeSync: canWrite && isPlatform,
      canChangePolicy: canWrite && !isDiscovery,
      canManageAdmins,
      canChangeGroups: canWrite,
      canChangeUsers: canWrite,
      canViewConversations: !assumeActive && !isDiscovery,
      canChangeCustomMessage: canWrite && !isDiscovery,
      canChangeBranding: canWrite,
      canChangeContextProviders: canWrite && isPlatform && ragActive,
    }),
    [canWrite, isPlatform, canManageAdmins, assumeActive, isDiscovery, ragActive]
  );

  const hasFeature = useCallback(
    (featureFlagVal: string): boolean => {
      if (!has(flags, featureFlagVal)) {
        return false;
      }

      return !!flags[featureFlagVal];
    },
    [flags]
  );

  const canChangeConnector = useCallback(
    (connector?: ConnectorModel) => {
      if (!canWrite || isTrial) {
        return false;
      }

      // in discovery mode, you can only work with ingress connectors
      if (isDiscovery && connector) {
        return connector.hasIngress;
      }

      return true;
    },
    [canWrite, isDiscovery, isTrial]
  );

  const getTooltip = useCallback(
    (feature: FeatureId) => {
      switch (orgMode) {
        case 'discovery':
          switch (feature) {
            case 'add-private-model':
              return 'Adding private models is disabled in discovery mode.';
            case 'change-private-model':
              return 'Making changes to private models is disabled in discovery mode.';
            case 'change-sync':
              return 'Managing directory sync is disabled in discovery mode.';
            case 'change-auth':
              return 'Changing Single Sign-On and Directory Sync settings is disabled in discovery mode.';
            case 'change-policy':
              return 'Changing Policy settings is disabled in discovery mode.';
            case 'view-conversation':
              return 'Viewing conversation history is disabled in discovery mode.';
            case 'add-policy':
              return 'Adding group policies is disabled in discovery mode.';
            case 'change-custom-message':
              return 'Changing custom messages is disabled in discovery mode.';
            case 'add-context-provider':
              return 'Adding context sources is disabled in discovery mode.';
            case 'change-context-provider':
              return 'Changing context sources is disabled in discovery mode.';
          }
          break;
        case 'trial':
          switch (feature) {
            case 'add-connector':
              return 'Adding connectors is disabled in trial mode.';
            case 'change-connector':
              return 'Making changes to connectors is disabled in trial mode.';
            case 'add-private-model':
              return 'Adding private models is disabled in trial mode.';
            case 'change-private-model':
              return 'Making changes to private models is disabled in trial mode.';
            case 'change-sync':
              return 'Managing directory sync is disabled in trial mode.';
            case 'change-auth':
              return 'Changing Single Sign-On and Directory Sync settings is disabled in trial mode.';
            case 'add-context-provider':
              return 'Adding context sources is disabled in trial  mode.';
            case 'change-context-provider':
              return 'Changing context sources is disabled in trial mode.';
          }
          break;
      }

      if (['change-context-provider', 'add-context-provider'].includes(feature)) {
        switch (ragStatus) {
          case 'inactive':
            return 'Organization-wide RAG is not yet active. Please add a connector that supports context sources to activate it.';
          case 'pending':
            return 'Organization-wide RAG is not yet active. Please wait a few minutes.';
          case 'failed':
            return 'Organization-wide RAG service is in a fail state. Please contact support.';
        }
      }

      if (feature === 'view-conversation' && assumeActive) {
        return 'Conversation history is not available when assuming a support role';
      }

      if (isAuditor) {
        return 'Adding, removing and modifying are disabled for the auditor role.';
      }

      return 'This feature is not available.';
    },
    [isAuditor, orgMode, assumeActive, ragStatus]
  );

  return {
    ...flags,
    canChangeConnector,
    orgMode,
    ragStatus: ragStatus || 'inactive',
    getTooltip,
    hasFeature,
    loading: orgLoading || ragLoading,
  };
};

export default useFeature;
