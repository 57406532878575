import { FC } from 'react';
import Drawer, { DrawerProps } from '..';
import DrawerHeader from '../header';
import Chip from '../../chip';
import { Box, Stack, Theme } from '@mui/material';
import DrawerContent from '../content';
import { useQuery } from 'react-query';
import ReadonlyView from '@/components/form/readonly-view';
import { ReadonlyField } from '@/components/form/readonly-view/helpers';
import { getNiceNumber } from '@/lib/helpers';
import ActionChip from '@/components/action-chip';
import Text from '@/components/text';
import { createUseStyles } from 'react-jss';
import CopyClipButton from '@/components/copy-clip-button';
import Icon from '@/components/icon';
import NiceDate from '@/components/nice-date';
import { getDomain } from '@/lib/url-helpers';
import NoneChip from '@/components/chip/none-chip';
import { UserEventModel } from '@/lib/models/user-event.model';
import { getUserEventById } from '@/lib/services/user-event.service';
import { QueryKey } from '@/lib/query-client';

interface Props extends DrawerProps {
  id: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    marginBottom: 15,
    paddingBottom: 8,
  },
}));

const userFields: ReadonlyField[] = [
  { label: 'Name', prop: 'name' },
  { label: 'Email', prop: 'email' },
];

const timesFields: ReadonlyField[] = [
  { label: 'Start Time', prop: 'startTime' },
  { label: 'End Time', prop: 'endTime' },
  { label: 'Duration', prop: 'duration' },
];

const policyFields: ReadonlyField[] = [
  { label: 'Request Type', prop: 'requestType' },
  { label: 'Request Domain', prop: 'requestUrl' },
  { label: 'Intent', prop: 'intent' },
  { label: 'Model', prop: 'model' },
  { label: 'Model Routing Destination', prop: 'modelRoutingDestination' },
  { label: 'Sensitive Data', prop: 'sensitiveData' },
  { label: 'Violations', prop: 'violations' },
  { label: 'Action', prop: 'action' },
  { label: 'Code', prop: 'code' },
  { label: 'Trace Id', prop: 'traceId' },
];

const getReadonlyData = (data: UserEventModel | null) => {
  if (!data) {
    return { user: {}, times: {}, policy: {} };
  }

  const {
    intentLabel,
    userClaim,
    startTime,
    endTime,
    serviceName,
    policyDecision,
    violations,
    compTraceId,
    requestType,
    requestUrl,
    sensitiveDataTypes,
    firewallDecision,
  } = data;

  const { name, email } = userClaim;

  const user = {
    name: name || <NoneChip notAvailable />,
    email,
  };

  const { codeLabel = '' } = policyDecision;

  const policy = {
    requestType,
    requestUrl: requestUrl ? getDomain(requestUrl) : <NoneChip />,
    intent: intentLabel || <NoneChip notAvailable />,
    model: serviceName,
    modelRoutingDestination: firewallDecision?.target?.name || <NoneChip />,
    sensitiveData: sensitiveDataTypes.length ? sensitiveDataTypes.join(', ') : <NoneChip />,
    action: <ActionChip action={policyDecision} />,
    code: codeLabel || <NoneChip />,
    violations: violations?.length ? (
      <>
        {violations.map((violation) => (
          <Chip key={violation} label={violation.toUpperCase()} color="error" size="small" />
        ))}
      </>
    ) : (
      <NoneChip />
    ),

    traceId: (
      <Stack direction="row" gap={1}>
        <Box>{compTraceId}</Box>
        <CopyClipButton value={compTraceId} />
      </Stack>
    ),
  };

  const durationMs = endTime - startTime;

  const times = {
    startTime: (
      <Stack direction="row" gap={0.8}>
        <NiceDate val={startTime} withTime withOffset />
      </Stack>
    ),
    endTime: (
      <Stack direction="row" gap={0.8}>
        <NiceDate val={endTime} withTime withOffset />
      </Stack>
    ),
    duration:
      durationMs < 1000 ? `${durationMs}ms` : `${getNiceNumber(Math.floor(durationMs / 1000))}s`,
  };

  return { user, times, policy };
};

export const UserEventDrawer: FC<Props> = ({ id, open, onClose, className }) => {
  const styles = useStyles();
  const query = useQuery([QueryKey.UserEventView, id], async () => getUserEventById(id), {
    enabled: !!id,
  });

  const { data = new UserEventModel() } = query;

  const { user, times, policy } = getReadonlyData(data);

  return (
    <Drawer open={open} onClose={onClose} className={className} query={query}>
      <DrawerHeader onClose={onClose}>User Event Details</DrawerHeader>
      <DrawerContent>
        <Box className={styles.header}>
          <Icon name="person" />
          <Text size="large">User</Text>
        </Box>
        <ReadonlyView fields={userFields} data={user} />

        <Box className={styles.header}>
          <Icon name="policy" />
          <Text size="large">Policy</Text>
        </Box>
        <ReadonlyView fields={policyFields} data={policy} />

        <Box className={styles.header}>
          <Icon name="time-lapse" />
          <Text size="large">Times</Text>
        </Box>
        <ReadonlyView fields={timesFields} data={times} />
      </DrawerContent>
    </Drawer>
  );
};

export default UserEventDrawer;
